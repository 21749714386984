import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  first,
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { UserPreferencesService } from '../../common/services/user-preferences/user-preferences.service';
import {
  ALIGN,
  COLUMN_CLICK_TYPE,
  COLUMN_TYPE,
  DropDownOption,
  GridCellData,
  GridColumnSchema,
  GridFilter,
  GridRowData,
  MCDate,
  SORT_ORDER,
} from '@maersk-global/angular-shared-library';
import { Screen } from '../../common/models/screen';
import * as countriesJSon from '../../common/models/countries.json';
import * as clusterJson from '../../common/models/countryCluster.json';
import { ClaimStatus } from '../../common/enum/claim-status';
import { UserClaimsAssignmentRequest } from '../../common/models/user-claims-assignment-request';
import { KeyValue } from '../../common/models/keyValue';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import {
  DUPLICATE_WORK_ORDER_MSG,
  ICON_ARROW_CLOCKWISE,
  ICON_PAPER_CLIP,
  TEMPLATE_TYPE,
  IA_IN_PROGRESS_TEXT,
  ICON_CLOCK_STOPWATCH,
  ICON_ARROW_CLOCKWISE_TIMES,
  IA_IMAGE_AVAILABLE_TEXT,
  Image_Source,
  IA_IN_PROGRESS_VALUE,
  IA_NOT_AVAILABLE,
  IA_BOTH_AVAILABLE,
  IA_EIR_AVAILABLE,
  IA_VENDOR_AVAILABLE,
} from '../../common/constants/app.constants';
import { AssignedUsersResponse } from '../../common/models/assignedUsersResponse';
import { environment } from '../../../environments/environment';
import { CountryDto } from '../../common/models/countryDto';
import { Region } from '../../common/models/region';
import {
  aboveCoverType,
  defaultFilters,
  getStatusTextFromStatusCode,
  statusTypeMaster,
  workOrderMode,
  workOrderStatusType,
} from '../../common/constants/temporary-constant';
import { UserClaimsUnassignmentRequest } from '../../common/models/user-claims-unassignment-request';
import { InspectionDetailsDTO } from '../../common/models/inspectionDetailsDTO';
import { logger } from '../../logger';
import { SharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { loggerType } from '../../common/models/logger-type';
import * as gridconfigurationMapping from './grid-configuration-mapping';
import { InvoiceStatusEnum } from '../../common/models/invoiceStatusEnum';
import { ShopResponse } from '../../common/models/shopResponse';
import { SharedDataService } from '../../shared-data-service';
import { RecoveryCaseService } from '../../common/services/recovery/recovery.service';
import { RecoveryRequest } from '../../common/models/recovery-request';
import { SortOrder } from '../../common/models/sortOrder';
import { VendorRecoveryCase } from '../../common/models/vendorRecoveryCase';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';

export class VendorRecoveryDataManager {
  eirImageFetchError: boolean = false;
  gridConfigMapping = gridconfigurationMapping.default;
  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _recoveryClaimService: RecoveryCaseService,
    private _userPreferencesService: UserPreferencesService,
    private _commonService: CommonService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService
  ) {}

  onGridColumnClick?: Function;
  apiVersion: string = '1.0';
  logger: logger = new logger();
  /**
   * grid filters are the default filters used by grid.
   */
  gridFilter: GridFilter = {
    pageNumber: 1,
    pageSize: 25,
    sortOrder: SORT_ORDER.ASC,
    sortColumn: '',
  };
  /**
   * request object used for API calls of customer recovery. We `always`/`will` maintain latest filter changes here.
   */
  vendorRecoveryRequest: RecoveryRequest = this.initVendorRecoveryObject();

  //to do via api
  countries: any = (countriesJSon as any).default;
  clusters: any = (clusterJson as any).default;
  eirAvailabilityIconClicked: boolean = false;

  private gridSchemaInitial$: Observable<GridColumnSchema[] | null> =
    this._userPreferencesService.vendorRecoveryScreen$.pipe(
      switchMap((value: Screen | undefined) =>
        this.createvendorRecoveryGridSchema(value)
      ),
      tap((schema) => this.gridSchemaSubject$$.next(schema))
    );

  private gridSchemaSubject$$: BehaviorSubject<GridColumnSchema[] | null> =
    new BehaviorSubject<GridColumnSchema[] | null>([]);

  private gridDataInitial$: Observable<GridRowData[] | null> =
    this._userPreferencesService.vendorRecoveryScreen$.pipe(
      switchMap(async (value: Screen | undefined) => {
        const tabIndex = await firstValueFrom(
          this._sharedRecoveryCaseService.currentTabIndex$
        );
        if (!value || tabIndex) return null;
        this.loadInitialFiltersForAllCasesGridView();
        await this.fetchDataFromServer();
        this.gridDataSubject$$.next(this.gridData);
        return this.gridData;
      })
    );

  private gridDataSubject$$: BehaviorSubject<GridRowData[] | null> =
    new BehaviorSubject<GridRowData[] | null>([]);

  private totalRecordsSubject$$: Subject<number> = new Subject<number>();

  totalRecords$?: Observable<number> =
    this.totalRecordsSubject$$.asObservable();

  gridSchema$: Observable<GridColumnSchema[] | null> =
    this.gridSchemaInitial$.pipe(first());

  gridData$: Observable<GridRowData[] | null> = merge(
    this.gridDataInitial$.pipe(take(1)),
    this.gridDataSubject$$.asObservable()
  );

  get gridSchema(): GridColumnSchema[] | null {
    return this.gridSchemaSubject$$.value;
  }

  set gridSchema(schema: GridColumnSchema[] | null) {
    this.gridSchemaSubject$$.next(schema);
  }

  get gridData(): GridRowData[] | null {
    return this.gridDataSubject$$.value;
  }

  set gridData(schema: GridRowData[] | null) {
    this.gridDataSubject$$.next(schema);
  }

  get userPreferences() {
    return this._userPreferencesService.userPreferences;
  }

  get screen() {
    return this.userPreferences?.preference.screens?.find(
      (screen) => (screen.id = 1)
    );
  }

  get podRegionFilter() {
    return this.screen?.searchCriteria?.find(
      (criteria) => criteria.key === 'podRegion'
    );
  }

  /**
   *
   * @param value screen specific preferences for the customer recovery grid.
   */
  async createvendorRecoveryGridSchema(value: Screen | undefined) {
    const tabIndex = await firstValueFrom(
      this._sharedRecoveryCaseService.currentTabIndex$
    );
    const userId = await (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (!value || !value.columns) return null;
    return value.columns.map((column) => {
      if (
        !column.name ||
        !column.displayName ||
        !column.columnType ||
        !column.sequence
      )
        return {} as GridColumnSchema;
      const filterMapping = this.gridConfigMapping.filterConfig.filter(
        (i: { name: any }) => i.name == column.name
      )[0];

      return {
        column: column.name,
        displayName: column.displayName,
        align: column.columnType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
        hidden: column.isHidden,
        columnType: column.columnType.toUpperCase() as COLUMN_TYPE,
        dateFormat: 'd MMM y h:mm:ss a',
        onClick: this.onGridColumnClick?.bind(this),
        columClickType: this.selectClickAbleColumnType(column.name),
        placeholderText: column.name === 'comments' ? 'View' : '',
        sequence: column.name === 'isReadyToInvoice' ? -1 : column.sequence,
        isClickable: this.isColumnClickable(column.name),
        filterType: filterMapping
          ? (filterMapping.type as GridColumnSchema['filterType'])
          : undefined,
        filterSequence: filterMapping ? filterMapping.sequence : 0,
        filterPlaceHolder: filterMapping ? filterMapping.placeholder : '',
        filterGroupName: filterMapping ? filterMapping.groupName : '',
        filterGroupId: filterMapping ? filterMapping.groupId : 0,
        disableColSorting: this.disableSorting(column.name),
        filterDisabled: this.getFilterDisabledStateByColumn(
          column.name,
          value,
          tabIndex
        ),
        loadFilterOptions: (
          dependentControlValue: string[] | string | MCDate | undefined
        ) =>
          this.getFilterOptionsByColumn(
            dependentControlValue,
            column.name,
            filterMapping
          ),
        filterDependsOnColumn: this.getDependentFilterControlName(column.name),
        loadFilterOptionsAsync: (
          dependentControlValue: string[] | string | MCDate | undefined
        ) =>
          this.getFilterOptionsByColumnAsync(
            dependentControlValue,
            column.name,
            userId
          ),
        columnHeaderHidden: column.name === 'isReadyToInvoice',
      };
    });
  }

  /**
   * This is the most important method where based of the filter options and customer recovery request object, we call various APIs to get grid data.
   */
  async fetchDataFromServer() {
    const tabIndex = await firstValueFrom(
      this._sharedRecoveryCaseService.currentTabIndex$
    );
    this.prepareVendorRecoveryRequest();
    // We are fetching total record count and customer recovery records with the specified filters.
    const result = await lastValueFrom(
      forkJoin({
        newTotalCount: this._recoveryClaimService
          .recoveryCasesCountGet(this.vendorRecoveryRequest)
          .pipe(map((response) => response as number)),
        vendorRecoveries: this._recoveryClaimService.recoveryCasesGet(
          this.vendorRecoveryRequest
        ),
      })
    );

    if (!result.vendorRecoveries || !result.vendorRecoveries?.data) {
      this.gridData = null;
      return;
    }
    this.totalRecordsSubject$$.next(result.newTotalCount);

    //Resetting grid if data is not available
    if (result.vendorRecoveries?.data.length === 0) {
      this.gridData = null;
      return;
    }
    // We are combining all the customer recovery records together so that we can show them in the grid in groups.
    this.gridData = this.reduceCustomerRecoveriesIntoGroups(
      [...result.vendorRecoveries.data],
      tabIndex
    );
    this.changeGridSchemaAfterDataLoaded(
      result.vendorRecoveries?.data,
      tabIndex
    );
  }

  /**
   * This method hides/shows the grid schema columns based on the data.
   * @param vendorRecoveryDetails customer recovery details
   * @returns
   */
  changeGridSchemaAfterDataLoaded(
    vendorRecoveryDetails: Array<VendorRecoveryCase>,
    tabIndex: number
  ) {
    switch (tabIndex) {
      case 0: // All Cases
        this.changeGridSchemaForAllCasesTab();
        break;
      case 1: // My Cases
        this.changeGridSchemaForMyCasesTab();
        break;
      case 2: // Completed Cases
        this.changeGridSchemaForCompletedCasesTab();
        break;
    }

    const isReadyToInvoiceColumn = this.gridSchema?.find(
      (col) => col.column === 'isReadyToInvoice'
    );
    if (!isReadyToInvoiceColumn) return;
    // const casesReadyForInvoice = vendorRecoveryDetails.find(
    //   (recoveryCase) => recoveryCase.i
    // );
    // isReadyToInvoiceColumn.hidden = !casesReadyForInvoice;

    this.gridSchema = this.gridSchema;
  }

  /**
   * This method prepares the grid schema for All Cases tab.
   */
  private changeGridSchemaForAllCasesTab() {
    this.changeColumnVisibilityByTab(0);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = true;
      statusIdCol.loadFilterOptions = () =>
        statusTypeMaster.filter((i) => !i.hide);
    }

    const assignedToNameCol = this.gridSchema?.find(
      (col) => col.column === 'assignedToName'
    );
    if (assignedToNameCol) assignedToNameCol.filterDisabled = false;
  }

  /**
   * This method prepares the grid schema for My Cases tab.
   */
  private changeGridSchemaForMyCasesTab() {
    this.changeColumnVisibilityByTab(1);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = true;
      statusIdCol.loadFilterOptions = () =>
        statusTypeMaster.filter(
          (statusType) => !statusType.hide
          //&&
          //this.vendorRecoveryRequest.caseStatuses?.includes(statusType.value)
        );
    }
  }

  /**
   * This method prepares the grid schema for Completed Cases tab.
   */
  private changeGridSchemaForCompletedCasesTab() {
    this.changeColumnVisibilityByTab(2);

    //Setting status column properties based on selected tab
    const statusIdCol = this.gridSchema?.find(
      (col) => col.column === 'claimStatusId'
    );
    if (statusIdCol) {
      statusIdCol.isClickable = false;
      statusIdCol.loadFilterOptions = () =>
        statusTypeMaster.filter(
          (statusType) => !statusType.hide
          // &&  this.vendorRecoveryRequest.statusIds?.includes(statusType.value)
        );
    }
  }

  /**
   * This method hides/shows the grid schema columns based on the data and selected tab.
   */
  private changeColumnVisibilityByTab(tabIndex: number) {
    // get hidden columns from grid configuration mapping
    const hiddenColumns: string[] =
      this.gridConfigMapping.hideColumns[
        tabIndex as keyof typeof this.gridConfigMapping.hideColumns
      ];
    if (hiddenColumns) {
      hiddenColumns.forEach((colName) => {
        const col = this.gridSchema?.find((col) => col.column === colName);
        if (col) col.hidden = true;
      });
    }

    // make columns visible which were visible in user preferences and which were hidden for other tabs.
    const visibleColumnsForvendorRecoveryGrid = this.screen?.columns
      ?.filter((column) => !column.isHidden)
      .map((column) => column.name);
    const columnsToMakeVisible = this.gridSchema?.filter(
      (col) =>
        !hiddenColumns?.includes(col.column) &&
        visibleColumnsForvendorRecoveryGrid?.includes(col.column)
    );
    columnsToMakeVisible?.forEach((col) => {
      col.hidden = false;
    });
  }

  /**
   * This method calls the API to update the new grid schema (mostly column re-order) state as user preferences.
   * @param schema Grid schema
   * @returns promise
   */
  async updateGridSchemaToServer(schema: GridColumnSchema[]) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (!this.userPreferences) return;
    this.screen?.columns?.forEach((column) => {
      const schemaColumn = schema.find(
        (columnSchema) => columnSchema.column === column.name
      );
      column.isHidden = schemaColumn?.hidden;
      column.sequence = schemaColumn?.sequence;
      column.isSticky = schemaColumn?.isSticky;
    });
    this.userPreferences.userId = userId;
    await this._userPreferencesService.updateUserPreferences(
      this.userPreferences
    );
  }

  /**
   * This method saves the user preferences filter data to server.
   * @param defaultFilterApplied is default filter reset
   * @returns
   */
  async updateGridFiltersToServer(defaultFilterApplied?: boolean) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (!this.userPreferences) return;

    if (!this.screen || !this.gridFilter.filters) return;
    this.userPreferences.userId = userId;

    this.gridFilter.saveFilterConfiguration = false;

    // If user wants default filters to be applied, we will save default filters as user-preferences and return.
    if (defaultFilterApplied) {
      this.assignSystemDefaultFilters();
    }

    // We are always storing default region preference for the user (but only when filter is not reset).
    await this.updatePodRegionFilterOptionsAsPerRegionSelection(
      !!defaultFilterApplied
    );

    this.screen.searchCriteria = [];
    // If user has opted for saving filters and it is not default, then save the user's own filters.
    Object.entries(this.gridFilter.filters).forEach(([key, value]) => {
      if (
        !['pageNumber', 'pageSize', 'sortOrder', 'sortColumn'].includes(key) &&
        value &&
        (!Array.isArray(value) || (Array.isArray(value) && value.length > 0))
      ) {
        this.screen?.searchCriteria?.push({
          key: key,
          value: JSON.stringify(value),
        });
      }
    });
    await this._userPreferencesService.updateUserPreferences(
      this.userPreferences
    );
    this.gridFilter.defaultFilterApplied = false;
  }

  /**
   * If filter added for `podRegion` column then disabled that column filter immediately from grid filter.
   */
  async updatePodRegionFilterOptionsAsPerRegionSelection(
    defaultFilterApplied: boolean
  ) {
    // We are always storing default region preference for the user (But only if user has not reset filter).
    if (
      !defaultFilterApplied &&
      this.podRegionFilter?.key &&
      this.podRegionFilter.value
    ) {
      this.gridFilter.filters = {
        ...this.gridFilter.filters,
        podRegion: JSON.parse(this.podRegionFilter.value),
      };
    }

    const podRegionColumn = this.gridSchema?.find(
      (col) => col.column === 'podRegion'
    );

    if (!this.gridFilter.filters || !podRegionColumn) return;

    let podRegionOptions = await lastValueFrom(
      this._sharedDataService.regions$.pipe(
        map((response: Region[] | undefined) => {
          if (!response || response.length < 1) return [];
          return response.map((region) => ({
            value: region.regionCode,
            label: region.regionName,
            name: region.regionName,
          }));
        })
      )
    );

    // Default filter is not applied so only show the filter options for the selected regions in region filter dropdown.
    if (
      !defaultFilterApplied &&
      Object.entries(this.gridFilter.filters).find(
        ([key, _]) => key === 'podRegion'
      )
    ) {
      podRegionOptions = podRegionOptions.filter((region) =>
        (this.gridFilter.filters!['podRegion'] as string[]).includes(
          region.value as string
        )
      );
    }
    podRegionColumn.loadFilterOptionsAsync = () => of(podRegionOptions);
    this.gridSchema = this.gridSchema;
  }

  /**
   * Updates the recovery case row in the grid data to indicate that
   * an Eir image is available.
   * @param cellData - The data of the cell that contains the
   * recovery case number to be updated.
   */
  updateRecoveryWithAvailabilityStatus(
    cellData: any,
    imageCoverage: number,
    imageCoverageText: string,
    icon: string
  ) {
    const row = this.gridData![cellData.rowNumber].row;
    const vendorRecovery =
      row['id'].value === cellData.cellValue.value
        ? row
        : this.gridData![cellData.rowNumber].childRows?.find(
            (item: any) => item['id'].value === cellData.cellValue.value
          );

    if (!vendorRecovery) return;

    vendorRecovery['imageCoverage'].value = imageCoverage;
    vendorRecovery['imageCoverageText'].value = imageCoverageText;
    vendorRecovery['id'].icon = icon;
    vendorRecovery['id'].tooltipText = imageCoverageText;
    this.gridData = [...this.gridData!];
  }

  /**
   * This method maps the filters applied with the API request object for getting the claim records accordingly.
   */
  updateVendorRecoveryRequestAsPerFilterObject() {
    if (this.gridFilter?.filters) {
      Object.keys(this.gridFilter?.filters)?.forEach((key, _) => {
        const keyValue = this.gridFilter?.filters
          ? this.gridFilter?.filters[key]
          : '';
        if (key === 'woCreatedOn') {
          this.vendorRecoveryRequest = {
            ...this.vendorRecoveryRequest,
            // dateRangeFromDateTime: (keyValue as MCDate).from ,
            // dateRangeToDateTime: (keyValue as MCDate).to,
          };
          return;
        }
        const filterColumDetails = this.gridConfigMapping.filterConfig?.filter(
          (x: any) => x.name == key
        )[0];
        this.vendorRecoveryRequest = {
          ...this.vendorRecoveryRequest,
          [filterColumDetails && filterColumDetails.filtername
            ? filterColumDetails.filtername
            : key]: keyValue ?? null,
        };
      });
    }
    // Doing this change as API can not sort on `isReadyToInvoice`. This property is not stored in DB.
    if (
      this.vendorRecoveryRequest.paginationSortColumn === 'isReadyToInvoice'
    ) {
      this.vendorRecoveryRequest.paginationSortColumn = 'dateToInvoice';
    }
  }

  /**
   * This method adds the system default filters when no user preference is saved or when preferences are removed.
   */
  assignSystemDefaultFilters() {
    this.gridFilter.filters = {};
    this.gridFilter.defaultFilterApplied = true;
    defaultFilters.forEach((defaultFilter) => {
      if (this.gridFilter.filters && defaultFilter.key && defaultFilter.value)
        this.gridFilter.filters[defaultFilter.key] = JSON.parse(
          defaultFilter.value
        );
    });
  }

  /**
   * This method updates the customer recovery request with some default values as per the current tab.
   * @param tabIndex current tab selected
   * @returns
   */
  async updateVendorRecoveryRequestAsPerCustomFilters(tabIndex: number) {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (this.gridFilter.newFiltersApplied) {
      this.resetSortingAndPagination();
      this.gridFilter.newFiltersApplied = false;
    }
    this.vendorRecoveryRequest = this.initVendorRecoveryObject();
    this.updateVendorRecoveryRequestAsPerFilterObject();
    // `My Cases` tab will always use current user name filter and specific selected or active claim statuses for getting the result from API.
    if (tabIndex === 1 && this.gridFilter.filters) {
      // this.vendorRecoveryRequest.caseStatuses =
      //   this.vendorRecoveryRequest.caseStatuses ??
      //   statusTypeMaster
      //     .filter((status) => !status.isCompleted)
      //     .map((status) => status.value);
      this.vendorRecoveryRequest.assignedTo = userId;
      return;
    }
    // `Completed` tab will always use current user name filter and specific selected statuses or passive claim statuses for getting the result from API.
    if (tabIndex === 2 && this.gridFilter.filters) {
      // this.vendorRecoveryRequest.caseStatuses =
      //   this.vendorRecoveryRequest.caseStatuses ??
      //   statusTypeMaster
      //     .filter((status) => status.isCompleted)
      //     .map((status) => status.value);
      this.vendorRecoveryRequest.assignedTo = userId;
    }
  }

  /**
   * We are using user preferences to load the initial state of the grid view.
   */
  loadInitialFiltersForAllCasesGridView() {
    const userPreferences = this._userPreferencesService.userPreferences;
    const screen = userPreferences?.preference.screens?.find(
      (screen) => (screen.id = 1)
    );
    this.gridFilter.filters = {};
    this.gridFilter.enableDefaultFilterConfiguration = true;
    this.gridFilter.newFiltersApplied = false;
    this.gridFilter.defaultFilterApplied = false;

    this.resetSortingAndPagination();
    this.vendorRecoveryRequest = this.initVendorRecoveryObject();
    // Use system default filters if no user-preferences are saved.
    if (!screen || !screen.searchCriteria || !screen.searchCriteria.length) {
      this.assignSystemDefaultFilters();
    }
    screen?.searchCriteria?.forEach((keyVal: KeyValue) => {
      if (!keyVal.value || !keyVal.key || !this.gridFilter.filters) return;
      this.gridFilter.filters[keyVal.key ?? ''] = JSON.parse(keyVal.value);
    });
    this.updateVendorRecoveryRequestAsPerFilterObject();
  }

  /**
   * We are resetting grid view based on the current tab.
   */
  resetFiltersAsPerCurrentTab(tabIndex: number) {
    this.gridFilter.filters = {};
    this.gridFilter.newFiltersApplied = false;
    this.gridFilter.enableDefaultFilterConfiguration = false;
    this.resetSortingAndPagination();
    this.vendorRecoveryRequest = this.initVendorRecoveryObject();
    // `All Cases` tab will show filter save option and show all statuses of claim.
    if (tabIndex === 0) {
      this.gridFilter.enableDefaultFilterConfiguration = true;
      // this.vendorRecoveryRequest.caseStatuses = statusTypeMaster.map(
      //   (status) => status.value
      // );
    }
    // `My Cases` tab will show current user's cases and show all non-complete statuses of claim.
    if (tabIndex === 1) {
      //this.vendorRecoveryRequest.assignedTo = userId;
      // this.vendorRecoveryRequest.caseStatuses = statusTypeMaster
      //   .filter((status) => !status.isCompleted)
      //   .map((status) => status.value);
    }
    // `Completed` tab will show current user's cases and show all only complete (canceled, completed, autoClosed etc) statuses of claim.
    if (tabIndex === 2) {
      //this.vendorRecoveryRequest.assignedTo = userId;
      // this.vendorRecoveryRequest.caseStatuses = statusTypeMaster
      //   .filter((status) => status.isCompleted)
      //   .map((status) => status.value);
    }
    this.addPodRegionInGridFilterAndRecoveryRequest();
  }

  addPodRegionInGridFilterAndRecoveryRequest() {
    // Assign cluster filter by default for the current user.
    if (this.podRegionFilter && this.podRegionFilter.value) {
      this.vendorRecoveryRequest.podRegions = JSON.parse(
        this.podRegionFilter.value
      );
      this.gridFilter.filters = {
        ...this.gridFilter.filters,
        podRegion: this.vendorRecoveryRequest.podRegions,
      };
    }
  }

  /**
   * Resets pagination and sorting to default values
   */
  resetSortingAndPagination() {
    //Resetting grid filter (pagination and sorting) to default values
    this.gridFilter.pageNumber = 1;
    this.gridFilter.pageSize = 25;
    this.gridFilter.sortOrder = SORT_ORDER.ASC;
    this.gridFilter.sortColumn = '';
  }

  initVendorRecoveryObject(): RecoveryRequest {
    return {
      paginationPageNumber: this.gridFilter.pageNumber,
      paginationPageSize: this.gridFilter.pageSize,
      paginationSortOrder:
        this.gridFilter.sortOrder == SORT_ORDER.ASC
          ? SortOrder.Ascending
          : SortOrder.Descending,
      paginationSortColumn: this.gridFilter.sortColumn,
      recoveryCaseType: 'Vendor',
    };
  }

  /**
   * We are doing modification of API request object so that it is exactly the way API wants it.
   */
  private prepareVendorRecoveryRequest() {
    // This conversion is required for API to work properly. Hence formatting the date.
    // this.vendorRecoveryRequest.dateRangeFromDateTime = this.vendorRecoveryRequest.dateRangeFromDateTime
    //   ? this.convert_dd_mm_yyyy_into_dateFormat(
    //       this.vendorRecoveryRequest.dateRangeFromDateTime,
    //       'dateFrom'
    //     )
    //   : undefined;
    // this.vendorRecoveryRequest.dateTo = this.vendorRecoveryRequest.dateTo
    //   ? this.convert_dd_mm_yyyy_into_dateFormat(
    //       this.vendorRecoveryRequest.dateTo,
    //       'dateTo'
    //     )
    //   : undefined;

    // Lets remove null and empty fields from the request object.
    const vendorRecoveryKeyVal = this.vendorRecoveryRequest;

    Object.entries(vendorRecoveryKeyVal).forEach(([key, value]) => {
      // if (!value && key !== 'importReturn')
      //   vendorRecoveryKeyVal[key] = undefined;
    });
  }

  /**
   * Converting date to a specified format.
   * @param date date
   * @returns
   */
  private convert_dd_mm_yyyy_into_dateFormat(date: string, key: string) {
    const arrDate = date?.split('-');
    if (!date) return undefined;
    else
      return `${arrDate[2].trim()}-${arrDate[1].trim()}-${arrDate[0].trim()}T${key === 'dateFrom' ? '00:00:00.000' : '23:59:59.999'}Z`;
  }

  /**
   * This method groups all the records together as a master and child row, to show linked cases.
   * Use this method to custom update any data if you want. For e.g. We can changing ClaimStatus enum as text in this method.
   * @param listOfCustomerRecoveries All un-grouped customer recovery records.
   * @returns
   */
  private reduceCustomerRecoveriesIntoGroups(
    listOfVendorRecoveries: VendorRecoveryCase[],
    tabIndex: number
  ): GridRowData[] {
    return listOfVendorRecoveries.reduce(
      (previousRows: GridRowData[], currentRow: VendorRecoveryCase) => {
        // Do any modifications you want with data.
        const currentGridRow = this.generateGridRowFromVendorRecovery(
          currentRow,
          tabIndex
        );

        previousRows.push({
          row: currentGridRow,
          isMasterRow: false,
          childRows: [],
          showChildRowData: false,
        });
        return previousRows;
      },
      [] as GridRowData[]
    );
  }

  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param vendorRecovery original object received from web-api response
   * @returns grid row object created
   */
  private generateGridRowFromVendorRecovery(
    vendorRecovery: VendorRecoveryCase,
    tabIndex: number
  ): { [key: string]: GridCellData } {
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(vendorRecovery).map((key) => {
      gridRowObject[key] = {
        value: this.getValueForCellByColumn(key, vendorRecovery),
        icon: this.getIconForCellByColumn(key, vendorRecovery, tabIndex),
        tooltipText: this.getTooltipForCellByColumn(
          key,
          vendorRecovery,
          tabIndex
        ),
        disabled: this.getDisabledStatusForCellByColumn(key, vendorRecovery),
        onIconClick: this.onCellIconClicked.bind(this),
      } as GridCellData;
    });
    return gridRowObject;
  }

  /**
   * This method handles the callback methods of the cell icon click event.
   * @param cellData - The data of the clicked cell.
   */
  async onCellIconClicked(cellData: any) {
    if (
      cellData &&
      cellData.cellValue &&
      cellData.cellValue.icon === 'arrow-clockwise'
    ) {
    }
  }

  /**
   * This method gets the value which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param vendorRecovery original object received from web-api response
   * @returns
   */
  private getValueForCellByColumn(
    columnName: string,
    vendorRecovery: VendorRecoveryCase
  ) {
    const vendorRecoveryKeyValue = vendorRecovery as {
      [key: string]: unknown;
    };
    if (columnName === 'claimStatusId')
      return ClaimStatus[vendorRecoveryKeyValue[columnName] as number];
    else if (columnName === 'gateInToWorkOrderDays')
      return `${vendorRecoveryKeyValue[columnName]} days`;
    else if (
      columnName === 'customerGateInLapseDays' &&
      vendorRecoveryKeyValue[columnName]
    )
      return `${vendorRecoveryKeyValue[columnName]} days`;
    else if (columnName === 'workOrderStatusCode')
      return workOrderStatusType.find(
        (x) =>
          x.value === parseInt(vendorRecoveryKeyValue[columnName] as string, 10)
      )?.label;
    else if (columnName === 'isReadyToInvoice') return '';
    else if (columnName === 'invoiceStatus')
      return getStatusTextFromStatusCode(
        vendorRecoveryKeyValue[columnName] as InvoiceStatusEnum
      );
    else if (
      columnName === 'woNumber' &&
      parseInt(vendorRecoveryKeyValue[columnName] as string) === 0
    )
      return '-';
    return vendorRecoveryKeyValue[columnName];
  }

  /**
   * This method gets the icon which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param vendorRecovery original object received from web-api response
   * @returns
   */
  private getIconForCellByColumn(
    columnName: string,
    vendorRecovery: VendorRecoveryCase,
    tabIndex: number
  ) {
    return undefined;
  }

  /**
   * This method gets the tooltip [text] which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param vendorRecovery original object received from web-api response
   * @returns
   */
  private getTooltipForCellByColumn(
    columnName: string,
    vendorRecovery: VendorRecoveryCase,
    tabIndex: number
  ) {
    return undefined;
  }

  /**
   * This method gets the tooltip [text] which we have to show in grid row cell.
   * @param columnName current column name ( property name) of the API Object
   * @param vendorRecovery original object received from web-api response
   * @returns
   */
  private getDisabledStatusForCellByColumn(
    columnName: string,
    vendorRecovery: VendorRecoveryCase
  ) {
    return undefined;
  }

  /**
   * Get filter options by column name.
   * @param columnName column name
   * @param filterMapping filerMapping
   * @returns
   */
  private getFilterOptionsByColumn(
    _: string[] | string | MCDate | undefined,
    columnName: string | undefined,
    filterMapping: any
  ): DropDownOption[] | undefined {
    if (!columnName) return undefined;
    else if (
      columnName == 'woCreatedOn' ||
      columnName == 'importReturn' ||
      columnName == 'customerGateInLapseDays' ||
      columnName == 'imageCoverage'
    )
      return filterMapping.options as DropDownOption[];
    else if (columnName == 'claimStatusId')
      return (
        statusTypeMaster.filter((i) => !i.hide) ?? ([] as DropDownOption[])
      );
    else if (columnName == 'aboveCoverageBand') return aboveCoverType;
    else if (columnName == 'workOrderStatusCode')
      return (
        workOrderStatusType.filter((i) => i.value != 0) ??
        ([] as DropDownOption[])
      );
    else if (columnName == 'woMode') return workOrderMode;
    else return undefined;
  }

  /**
   *
   * @param columnName name of the current column
   * @returns Observable
   */
  private getFilterOptionsByColumnAsync(
    dependentControlValue: string[] | string | MCDate | undefined,
    columnName: string | undefined,
    userId: string
  ): Observable<DropDownOption[] | undefined> {
    if (columnName === 'assignedToName' || columnName === 'createdBy') {
      return this._customerRecoveryClaimService
        .customerRecoveryClaimsAssignedUsersGet(this.apiVersion)
        .pipe(
          map((response: AssignedUsersResponse | undefined) => {
            // Return an empty array if there's no response or no assigned users
            if (!response || !response.assignedUsers) return [];
            // Map the response to dropdown options
            const users = response.assignedUsers.map((user) => ({
              value: user.userId,
              label: `${user.userName} (${user.userId})`,
              name: user.userId,
            }));
            const indexOfLoggedInUser = users.findIndex(
              (user) => user.value === userId
            );
            const removedUser = users.splice(indexOfLoggedInUser, 1);

            // Sort the users by label
            users.sort((a, b) => a.label.localeCompare(b.label));
            users.unshift(removedUser[0]);
            users.unshift({ name: 'System', value: 'SYS', label: 'System' });
            return users;
          }),
          shareReplay(1)
        );
    } else if (
      !!dependentControlValue &&
      (columnName == 'shopCountryCode' || columnName == 'podCountryName')
    ) {
      return this._sharedDataService.countries$.pipe(
        map((response: CountryDto[] | undefined) => {
          if (!response || response.length < 1) return [];
          return response
            .filter(
              (country) =>
                country.clusterCode &&
                (dependentControlValue as string[]).includes(
                  country.clusterCode
                )
            )
            .map((country) => ({
              value: country.code,
              label: country.name,
              name: country.name,
            }));
        }),
        shareReplay(1)
      );
    } else if (!!dependentControlValue && columnName == 'podCluster') {
      return this._sharedDataService.countries$.pipe(
        map((countries) => {
          if (!countries || countries.length < 1) return [];
          return countries
            .filter(
              (country, index) =>
                country.regionCode &&
                (dependentControlValue as string[]).includes(
                  country.regionCode
                ) &&
                countries.findIndex(
                  (o) => o.clusterCode === country.clusterCode
                ) === index
            )
            .map((cluster) => ({
              value: cluster.clusterCode,
              label: cluster.clusterName,
              name: cluster.clusterName,
            }));
        }),
        shareReplay(1)
      );
    } else if (columnName == 'podRegion') {
      return this._sharedDataService.regions$.pipe(
        map((response: Region[] | undefined) => {
          if (!response || response.length < 1) return [];
          let regions = response.map((region) => ({
            value: region.regionCode,
            label: region.regionName,
            name: region.regionName,
          }));
          if (this.podRegionFilter)
            regions = regions.filter((region) =>
              (
                JSON.parse(this.podRegionFilter?.value ?? '') as string[]
              ).includes(region.value || '')
            );
          return regions;
        }),
        shareReplay(1)
      );
    } else if (!!dependentControlValue && columnName == 'shopCode') {
      return this._sharedDataService.shopCodes$.pipe(
        map((shopCodes: ShopResponse[] | undefined) => {
          if (!shopCodes || shopCodes.length < 1) return [];
          return shopCodes
            .filter(
              (shop) =>
                shop.countryCode &&
                (dependentControlValue as string[]).includes(shop.countryCode)
            )
            .map((shop) => ({
              value: shop.code,
              label: shop.code,
              sublabel: shop.code,
            }));
        }),
        shareReplay(1)
      );
    } else return of(undefined);
  }

  private getDependentFilterControlName(name: string): string {
    return name === 'podCountryName' || name === 'shopCountryCode'
      ? 'podCluster'
      : name === 'podCluster'
        ? 'podRegion'
        : name === 'shopCode'
          ? 'shopCountryCode'
          : '';
  }

  /**
   * This method enables or disables initial specific filter option for specific column based on some condition.
   * @param columnName name of the current column
   * @param screen userPreference details of the current user
   * @returns
   */
  private getFilterDisabledStateByColumn(
    columnName: string,
    screen: Screen,
    tabIndex: number
  ) {
    return false;
  }

  /**
   * We are configuring various columns with click type event so that they will be rendered by event type inside grid.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private selectClickAbleColumnType(
    columnName: string
  ): COLUMN_CLICK_TYPE | undefined {
    switch (columnName) {
      case 'id':
        return COLUMN_CLICK_TYPE.LINK_CLICK;
      default:
        return COLUMN_CLICK_TYPE.LINK_CLICK;
    }
  }

  /**
   * We are checking if the column provided is clickable or not based on some custom logic.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private isColumnClickable(columnName: string): boolean {
    if (columnName === 'id') return true;
    return false;
  }
  /**
   * We are checking if the column provided has sorting enable or disable.
   * @param columnName columnName to configure
   * @returns column click event type
   */
  private disableSorting(columnName: string): boolean {
    return false;
  }

  /**
   * Checks if the time difference between the current time and the given time is greater than the specified threshold in hours.
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @param thresholdHour - The threshold in hours to compare the time difference against.
   * @returns true if the time difference is greater than the threshold, false otherwise.
   */
  private isTimeDifferenceGreaterThanThreshold(
    lastRecordedTime: Date | undefined,
    thresholdHour: number
  ): boolean {
    if (!lastRecordedTime) return true;
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);
    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    // Return true if the time difference in hours is greater than the threshold, false otherwise
    return timeDifferenceInHours > thresholdHour;
  }

  /**
   * Calculates the remaining time in minutes between the current time and the given time
   *
   * @param lastRecordedTime - The Date object representing the last recorded time.
   * @returns String with remaining time in hours, minutes, and seconds if the time difference is less than the threshold,
   *          otherwise null.
   */
  private getRemainingMinutes(
    lastRecordedTime: Date,
    thresholdHour: number
  ): string {
    const currentTime = new Date();
    const lastRecordedTimeDate = new Date(lastRecordedTime);

    // Calculate the difference in milliseconds between the current time and the last recorded time
    const timeDifferenceInMs =
      currentTime.getTime() - lastRecordedTimeDate.getTime();

    // Convert the time difference from milliseconds to hours
    const timeDifferenceInHours = timeDifferenceInMs / (1000 * 60 * 60);

    // If the time difference is greater than the threshold, return null
    if (timeDifferenceInHours > thresholdHour) {
      return '00h-00m-00s';
    }

    // Calculate remaining time in hours, minutes, and seconds
    const remainingTimeInMs =
      thresholdHour * 60 * 60 * 1000 - timeDifferenceInMs;
    const remainingMinutes = Math.floor(
      (remainingTimeInMs % (1000 * 60 * 60)) / (1000 * 60)
    );

    return remainingMinutes + '';
  }
}
