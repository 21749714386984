import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthenticationService } from '@maersk-global/angular-shared-library';
import { catchError, switchMap, take, throwError } from 'rxjs';

export const idTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const authenticationService = inject(AuthenticationService);
  return authenticationService.user$.pipe(
    take(1),
    switchMap((user) => {
      req = req.clone({
        setHeaders: {
          'Id-Token': user.idToken ?? '',
        },
      });
      return next(req).pipe(catchError((error) => throwError(() => error)));
    })
  );
};
