import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  shareReplay,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  PanelComponent,
  Image,
  CustomFormValidators,
  ImageListerComponent,
  ToasterService,
  LoaderService,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CaseDocumentService } from '../../../common/services/case-document/case-document.service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { ClaimReferenceDocumentDto } from '../../../common/models/claimReferenceDocumentDto';
import { CaseDocumentMetadataDto } from '../../../common/models/caseDocumentMetadataDto';
import { EmailTemplateDto } from '../../../common/models/emailTemplateDto';
import { EmailTemplateService } from '../../../common/services/email/email-template.service';
import { EmailTemplateDtoIEnumerableResponse } from '../../../common/models/emailTemplateDtoIEnumerableResponse';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { EmailService } from '../../../common/services/email/email.service';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { FileInfoDto } from '../../../common/models/fileInfoDto';
import { EmailAttachmentDto } from '../../../common/models/emailAttachmentDto';
import { EmailTemplatePlaceholderService } from '../../../email-template-placeholder.service';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'liability-letter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PanelComponent,
    ImageListerComponent,
  ],
  templateUrl: './liability-letter.component.html',
  styleUrl: './liability-letter.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LiabilityLetterComponent implements OnChanges {
  emailForm: FormGroup = new FormGroup({});

  @Input({ required: true }) item?: TemplateModel;
  @Input({ required: true }) email?: SendMailRequest;

  apiVersion: string = '1.0';
  emailBodyContainer!: ElementRef;
  emailBody?: SafeHtml;
  placeHolderPopulatedTemplate: string = '';
  currencyCode = '';
  caseDetail!: CustomerRecoveryCaseDto | undefined;
  /**
   * attachment size while sending letter.
   */
  attachmentSize: string = '0';
  /**
   * attachment size error while sending letter.
   */
  attachmentError: boolean = false;
  /**
   * attachment files warning.
   */
  attachmentWarning: boolean = false;
  /**
   * send mail error after  sending letter.
   */
  sendMailError: boolean = false;
  /**
   *Main EIR(Inspection) assign images for the caseNumber.
   */
  attachments$?: Observable<Image[] | null>;
  /**
   * Initial list of assigned case images which we get from the API.
   */
  initialAttachments$?: Observable<Image[] | null>;
  /**
   * This is the subject holding the latest Attachments`.
   */
  attachmentsSubject$$: BehaviorSubject<Image[] | null> = new BehaviorSubject<
    Image[] | null
  >([]);
  /**
   * This is the subject holding the latest email Templates`.
   */
  templatesSubject$$: BehaviorSubject<EmailTemplateDto[] | null> =
    new BehaviorSubject<EmailTemplateDto[] | null>([]);
  /**
   *Main observable Email Templates
   */
  emailTemplates$?: Observable<EmailTemplateDto[] | null> =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      switchMap((recoveryData) => {
        return this._emailTemplateService
          .emailTemplateCountryClusterCodeGet(
            recoveryData!.podCountryClusterCode!
          )
          .pipe(
            map((response: EmailTemplateDtoIEnumerableResponse) => {
              if (!response || !response.isSuccess || !response.data)
                return null;
              return response.data;
            }),
            tap((templates) => this.templatesSubject$$.next(templates))
          );
      })
    );

  /**
   * observable holding dropdown value of templates
   */
  templateDropDown$?: Observable<DropDownOption[] | null> =
    this.emailTemplates$?.pipe(
      map((templates) => {
        if (!templates) return null;

        return templates
          .sort((a, b) => a.templateName.localeCompare(b.templateName)) // Sort based on templateName
          .map((template) => {
            return {
              label: template.templateName,
              value: template.id,
            } as DropDownOption;
          });
      })
    );

  maxEmailSize$: Observable<number> = this._sharedDataService.appConfig$.pipe(
    map((configs) => {
      const size = configs
        ?.find((config) => config.name === 'max_email_size')
        ?.value?.split('MB')[0];
      return parseInt(size ?? '');
    }),
    shareReplay(1)
  );

  totalLiabilityAmount$: Observable<number> =
    this._sharedRecoveryCaseService.damageDetails$.pipe(
      map((damageDetails) => {
        let totalLiabilityAmount = 0;
        if (damageDetails && damageDetails.length > 0) {
          totalLiabilityAmount =
            Math.round(
              damageDetails.reduce((total, damageDetail) => {
                total += damageDetail.amountInCaseCurrency ?? 0;
                return total;
              }, 0) * 100
            ) / 100;
        }
        return totalLiabilityAmount;
      })
    );

  ngOnInit(): void {
    if (!this.email) {
      this._sharedRecoveryCaseService.updateFormValidationState({
        component: Components.LiabilityLetterComponent,
        state: false,
      });
      return;
    }
    this.createEmailForm(this.email);
    this.validateControl();
  }

  ngOnChanges(_: SimpleChanges): void {
    this.ngOnInit();
  }

  constructor(
    protected _sanitizer: DomSanitizer,
    private _caseDocumentService: CaseDocumentService,
    private _emailTemplateService: EmailTemplateService,
    private _emailService: EmailService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _toasterService: ToasterService,
    private _loaderService: LoaderService,
    private _sharedDataService: SharedDataService,
    private _emailTemplatePlaceholderService: EmailTemplatePlaceholderService
  ) {}

  customerRecoveryData$ =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.caseDetail = recoveryData;
        this.currencyCode = this.caseDetail?.caseCurrency ?? '';
        this.populateImagesAssignedToCase();
      })
    );

  /**
   * This method creates the form controls for all the email attributes.
   */
  private createEmailForm(email: SendMailRequest) {
    this.emailForm = new FormGroup({
      to: new FormControl(email.to, [
        Validators.required,
        CustomFormValidators.multipleEmailValidator,
      ]),
      cc: new FormControl(email.cc, [
        CustomFormValidators.multipleEmailValidator,
      ]),
      subject: new FormControl(email.subject, [Validators.required]),
    });
  }

  populateImagesAssignedToCase() {
    this.initialAttachments$ = this._caseDocumentService
      .customerRecoveryClaimsCaseIdCaseDocumentGet(
        this.caseDetail?.caseId ?? 0,
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.claimReferenceDocuments) return null;
          return this.mapImageFromDto(response.claimReferenceDocuments);
        }),
        tap((attachments) => this.attachmentsSubject$$.next(attachments)),
        shareReplay(1)
      );

    this.attachments$ = merge(
      this.initialAttachments$,
      this.attachmentsSubject$$.asObservable().pipe(skip(2))
    );
  }

  validateControl() {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.LiabilityLetterComponent,
      state: !this.emailForm.invalid && !this.attachmentError,
    });
  }

  mapImageFromDto(documents: ClaimReferenceDocumentDto) {
    const attachments: Image[] = [];
    if (documents.eirs) {
      const metaDataImage = documents.eirs.filter((i) => i.eirId != 0);
      metaDataImage
        ?.flatMap((eir) => eir.documentMetadata)
        ?.forEach((doc) => {
          attachments.push(this.mapImageForAttachments(doc));
        });
    }
    if (documents.attachments) {
      documents?.attachments?.forEach((doc) => {
        attachments.push(this.mapImageForAttachments(doc));
      });
    }
    return attachments;
  }

  mapImageForAttachments(attachment: CaseDocumentMetadataDto) {
    return {
      id: attachment.id?.toString(),
      path: attachment.uri,
      isSelected: false,
      label: attachment.name.substring(attachment.name.indexOf('-') + 1),
      showSelector: true,
      header: 'eEIR',
      subHeader: `Damage Code - ${attachment.damageType}`,
      sizeInBytes: attachment.size,
      allowDownload: true,
      type: attachment.name
        .substring(attachment.name.lastIndexOf('.') + 1)
        .toLowerCase(),
    } as Image;
  }

  selectAllAttachment(event: any) {
    const attachments = this.attachmentsSubject$$.value;
    if (!attachments) return;
    attachments.forEach((i) => (i.isSelected = event.target.checked));
    this.attachmentsSubject$$.next([...attachments]);
    this.attachmentSelected(event.target.checked ? attachments : []);
  }

  async templateSelected(event: any) {
    const templates = this.templatesSubject$$.value;
    const selectedTemplate = templates?.filter(
      (i) => i.id == event.target.value
    )[0];
    if (this.email) {
      this.placeHolderPopulatedTemplate = await firstValueFrom(
        await this._emailTemplatePlaceholderService.getPlaceholderPopulatedTemplate(
          selectedTemplate?.templateContentForDisplay ?? '',
          this.email
        )
      );
      this.emailBody = this._sanitizer.bypassSecurityTrustHtml(
        this.placeHolderPopulatedTemplate
      );
      const subject = await firstValueFrom(
        await this._emailTemplatePlaceholderService.getPlaceholderPopulatedTemplate(
          selectedTemplate?.templateSubject ?? '',
          this.email
        )
      );
      this.emailForm.get('subject')?.setValue(subject);
    }
    this.validateControl();
  }

  async attachmentSelected(attachments: Image[]) {
    const maxEmailSize = await firstValueFrom(this.maxEmailSize$);
    this.attachmentError = false;
    this.attachmentWarning = false;
    this.attachmentsSubject$$.value?.forEach((i) => (i.isSelected = false)); //todo
    attachments.forEach((i) => (i.isSelected = true)); //todo
    const totalSizeByte = attachments.reduce((total, attachment) => {
      return (total += attachment.sizeInBytes ?? 0);
    }, 0);

    this.attachmentSize = ((await totalSizeByte) / (1024 * 1024)).toFixed(2);
    if ((await totalSizeByte) / (1024 * 1024) > (80 * maxEmailSize) / 100)
      //attachment selected size check if 80% of max file size
      this.attachmentWarning = true;
    if ((await totalSizeByte) / (1024 * 1024) >= maxEmailSize)
      this.attachmentError = true;
  }

  async sendMail() {
    this.sendMailError = false;
    const liabilityAmount = await firstValueFrom(this.totalLiabilityAmount$);

    //binding attachment to request
    const selectedAttachment = this.attachmentsSubject$$.value?.filter(
      (i) => i.isSelected
    );
    const emailAttachment = selectedAttachment?.reduce((file, currentFile) => {
      if (currentFile) {
        file.push({
          fileName: currentFile.label ?? '',
          fileNameWithPath: currentFile.path,
        });
      }
      return file;
    }, [] as FileInfoDto[]);

    //preparing request
    const to = this.emailForm?.get('to')?.value?.split(';');
    const cc = this.emailForm?.get('cc')?.value?.split(';');
    const request = {
      to: to?.join(';'),
      cc: cc?.join(';'),
      from: this.email?.from,
      body: this.placeHolderPopulatedTemplate,
      subject: this.emailForm?.get('subject')?.value,
      caseNumber: this.caseDetail?.recoveryCaseNumber,
      emailType: this.email?.emailType,
      liabilityLetter: {
        createdBy: this.email?.liabilityLetter?.createdBy,
        createdDate: this.email?.liabilityLetter?.createdDate,
        liabilityAmount: liabilityAmount,
        liabilityPartyName: this.email?.liabilityLetter?.liabilityPartyName,
        recoveryCaseId: this.caseDetail?.caseId,
      },
    } as SendMailRequest;
    if (emailAttachment && emailAttachment?.length > 0) {
      request.emailAttachment = {
        fileInfo: emailAttachment,
      } as EmailAttachmentDto;
    }
    const letterSentResponse = await lastValueFrom(
      this._emailService.emailPost(request, this.apiVersion)
    );
    this._loaderService.hideLoader();
    if (letterSentResponse.isSuccess) {
      this._sharedRecoveryCaseService.reloadLiabilityLetters();
      this._sharedRecoveryCaseService.updateLiabilityDetailsVisibility(false);
      this._toasterService.showToast({
        message: 'Liability letter sent to the customer.',
        type: 'success',
      });
      return;
    }
    this.sendMailError = true;
  }

  closeMailErrorNotification() {
    this.sendMailError = false;
  }
}
