<div *ngIf="isSiteUndermaintenance; else siteNotUndermaintenance">
  <div class="container">
    <h1>Site Launching Soon</h1>
    <p>
      We're currently working hard to bring you an amazing website. Please check
      back later!
    </p>
  </div>
</div>
<ng-template #siteNotUndermaintenance>
  <div>
    <app-header>
      <img src="assets/svg/logo.svg" />
      <span>Claims Management System</span>
    </app-header>
    <ng-container *ngIf="isSideNavLoaded">
      <app-sidenav></app-sidenav>
    </ng-container>
    <div>
      <app-content [showFullWidth]="!isSideNavLoaded">
        <router-outlet></router-outlet>
      </app-content>
    </div>
  </div>
</ng-template>
<app-modal-notification></app-modal-notification>
<lib-toaster></lib-toaster>
<lib-email-modal></lib-email-modal>
<lib-loader></lib-loader>
<app-help-faq-drawer></app-help-faq-drawer>
