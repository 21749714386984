/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type SortOrder = 'Ascending' | 'Descending';

export const SortOrder = {
    Ascending: 'Ascending' as SortOrder,
    Descending: 'Descending' as SortOrder
};