import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { DcrpAuthorizationService } from '../services/authorization/dcrp-authorization.service';
import { filter, take, tap } from 'rxjs';
import { User } from '../models/user';
import { McButton } from '@maersk-global/mds-components-core';

@Directive({
  selector: '[isAuthorizedFor]',
  standalone: true,
})
export class IsAuthorizedForDirective implements AfterViewInit {
  @Input('isAuthorizedFor') context?: string;

  constructor(
    private _authorizationService: DcrpAuthorizationService,
    private element: ElementRef
  ) {}

  ngAfterViewInit(): void {
    this._authorizationService
      .isAuthorizedFor(this.context)
      .pipe(
        take(1),
        filter((isAuthorized) => !isAuthorized),
        tap(() => {
          this.element.nativeElement.title =
            'Action disabled for the current user role';
          // For MDS Controls, set disabled property.
          if (['MC-BUTTON'].includes(this.element.nativeElement.nodeName)) {
            this.element.nativeElement.disabled = true;
          } else {
            // For other html elements, set cursor and opacity to make control disable.
            this.element.nativeElement.style.cursor = 'not-allowed';
            this.element.nativeElement.style.opacity =
              'var(--mds_brand_appearance_state_disabled_opacity)';
          }
        })
      )
      .subscribe();
  }
}
