/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { SortOrder } from '../../models/sortOrder';
import { UserIEnumerableResponse } from '../../models/userIEnumerableResponse';
import { UserResponse } from '../../models/userResponse';
import { UserPost } from '../../models/userPost';
import { User } from '../../models/user';

@Injectable()
export class UserService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Retrieves a list of users based on the specified search filter.
   *
   * @param firstName
   * @param lastName
   * @param emailId
   * @param loginId
   * @param isActive
   * @param paginationPageNumber
   * @param paginationPageSize
   * @param paginationSortOrder
   * @param paginationSortColumn
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userGet(
    firstName?: string,
    lastName?: string,
    emailId?: string,
    loginId?: string,
    isActive?: boolean,
    paginationPageNumber?: number,
    paginationPageSize?: number,
    paginationSortOrder?: SortOrder,
    paginationSortColumn?: string,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserIEnumerableResponse>;
  public userGet(
    firstName?: string,
    lastName?: string,
    emailId?: string,
    loginId?: string,
    isActive?: boolean,
    paginationPageNumber?: number,
    paginationPageSize?: number,
    paginationSortOrder?: SortOrder,
    paginationSortColumn?: string,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserIEnumerableResponse>>;
  public userGet(
    firstName?: string,
    lastName?: string,
    emailId?: string,
    loginId?: string,
    isActive?: boolean,
    paginationPageNumber?: number,
    paginationPageSize?: number,
    paginationSortOrder?: SortOrder,
    paginationSortColumn?: string,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserIEnumerableResponse>>;
  public userGet(
    firstName?: string,
    lastName?: string,
    emailId?: string,
    loginId?: string,
    isActive?: boolean,
    paginationPageNumber?: number,
    paginationPageSize?: number,
    paginationSortOrder?: SortOrder,
    paginationSortColumn?: string,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (firstName !== undefined && firstName !== null) {
      queryParameters = queryParameters.set('FirstName', <any>firstName);
    }
    if (lastName !== undefined && lastName !== null) {
      queryParameters = queryParameters.set('LastName', <any>lastName);
    }
    if (emailId !== undefined && emailId !== null) {
      queryParameters = queryParameters.set('EmailId', <any>emailId);
    }
    if (loginId !== undefined && loginId !== null) {
      queryParameters = queryParameters.set('LoginId', <any>loginId);
    }
    if (isActive !== undefined && isActive !== null) {
      queryParameters = queryParameters.set('IsActive', <any>isActive);
    }
    if (paginationPageNumber !== undefined && paginationPageNumber !== null) {
      queryParameters = queryParameters.set(
        'Pagination.PageNumber',
        <any>paginationPageNumber
      );
    }
    if (paginationPageSize !== undefined && paginationPageSize !== null) {
      queryParameters = queryParameters.set(
        'Pagination.PageSize',
        <any>paginationPageSize
      );
    }
    if (paginationSortOrder !== undefined && paginationSortOrder !== null) {
      queryParameters = queryParameters.set(
        'Pagination.SortOrder',
        <any>paginationSortOrder
      );
    }
    if (paginationSortColumn !== undefined && paginationSortColumn !== null) {
      queryParameters = queryParameters.set(
        'Pagination.SortColumn',
        <any>paginationSortColumn
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    return this.httpClient.request<UserIEnumerableResponse>(
      'get',
      `${this.basePath}/user`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Creates a new user in the system.
   *
   * @param body The user details provided in the request body.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userPost(
    body?: UserPost,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserResponse>;
  public userPost(
    body?: UserPost,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserResponse>>;
  public userPost(
    body?: UserPost,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserResponse>>;
  public userPost(
    body?: UserPost,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserResponse>(
      'post',
      `${this.basePath}/user`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Updates the details of an existing user in the system.
   *
   * @param body The updated user details provided in the request body.
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userPut(
    body?: User,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserResponse>;
  public userPut(
    body?: User,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserResponse>>;
  public userPut(
    body?: User,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserResponse>>;
  public userPut(
    body?: User,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<UserResponse>(
      'put',
      `${this.basePath}/user`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
