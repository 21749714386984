/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AuthPolicyIEnumerableResponse } from '../../models/authPolicyIEnumerableResponse';

@Injectable()
export class AuthService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * Retrieves all authorization policies configured in the application.
   *
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public authPoliciesGet(
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<AuthPolicyIEnumerableResponse>;
  public authPoliciesGet(
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<AuthPolicyIEnumerableResponse>>;
  public authPoliciesGet(
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<AuthPolicyIEnumerableResponse>>;
  public authPoliciesGet(
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<AuthPolicyIEnumerableResponse>(
      'get',
      `${this.basePath}/auth/policies`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
