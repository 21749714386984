import { Injectable } from '@angular/core';
import { CommonService } from '../customer-recovery/common.service';
import { UserPreferenceDTO } from '../../models/userPreferenceDTO';
import { Screen } from '../../models/screen';
import {
  BehaviorSubject,
  Observable,
  filter,
  lastValueFrom,
  map,
  merge,
  shareReplay,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { DcrpAuthorizationService } from '../authorization/dcrp-authorization.service';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private apiVersion: string = '1.0';
  /**
   * This variable is used for those users who do not have any preference added yet. For these users we do POST api call.
   */
  private isNewUser?: boolean = false;

  /**
   * Behavior Subject to store the current state of the user-preferences. Every time we modify user preference, we store new state here.
   */
  private userPreferencesSubject$$: BehaviorSubject<
    UserPreferenceDTO | undefined
  > = new BehaviorSubject<UserPreferenceDTO | undefined>(undefined);

  /**
   * This observable always emits latest changed user preferences. At first it gets this info from API and later on from behavior subject.
   */
  userPreferences$: Observable<UserPreferenceDTO | undefined> = merge(
    this.userPreferencesSubject$$.asObservable().pipe(
      // Skipping first 2 emissions as first value will be `undefined` and 2nd will be the same API result.
      skip(2)
    ),
    this._authorizationService.loggedInUser$.pipe(
      switchMap((user) =>
        this._commonService
          .commonUserPreferenceGet(user.uniqueId, this.apiVersion)
          .pipe(
            tap((userPreferences) => {
              // Lets store the value in behavior subject so that we can get this value while adding new preference.
              this.userPreferencesSubject$$.next(userPreferences);
              this.isNewUser = this.userPreferences?.userId
                .toUpperCase()
                .includes('XXX');
            })
          )
      )
    )
  ).pipe(shareReplay(1));

  /**
   * This observable always emits latest changed customerRecoveryScreen. This observable is depending on `userPreferences` observable.
   */
  customerRecoveryScreen$: Observable<Screen | undefined> =
    this.userPreferences$.pipe(
      filter((value) => !!value),
      map((userPreferences) =>
        userPreferences?.preference.screens?.find((x) => x.id === 1)
      )
    );

  /**
   * This observable always emits latest changed customerRecoveryScreen. This observable is depending on `userPreferences` observable.
   */
  vendorRecoveryScreen$: Observable<Screen | undefined> =
    this.userPreferences$.pipe(
      filter((value) => !!value),
      map((userPreferences) =>
        userPreferences?.preference.screens?.find((x) => x.id === 2)
      )
    );

  constructor(
    private _commonService: CommonService,
    private _authorizationService: DcrpAuthorizationService
  ) {}

  /**
   * getter for giving current state of the user preferences.
   */
  get userPreferences(): UserPreferenceDTO | undefined {
    return this.userPreferencesSubject$$.value;
  }

  /**
   * Method for updating current state of the user preferences.
   */
  async updateUserPreferences(
    userPreferenceDTO: UserPreferenceDTO | undefined
  ) {
    if (!this.userPreferences) return;
    if (this.isNewUser) {
      await lastValueFrom(
        this._commonService.commonUserPreferencePost(
          userPreferenceDTO as UserPreferenceDTO,
          this.apiVersion
        )
      );
      this.isNewUser = false;
    } else
      await lastValueFrom(
        this._commonService.commonUserPreferencePut(
          userPreferenceDTO as UserPreferenceDTO,
          this.apiVersion
        )
      );
    this.userPreferencesSubject$$.next(userPreferenceDTO);
  }
}
