import { CommonModule } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, map, Observable, shareReplay } from 'rxjs';
import { EmailTemplateDto } from '../../common/models/emailTemplateDto';
import { EmailTemplateService } from '../../common/services/email/email-template.service';
import { EmailTemplateDtoIEnumerableResponse } from '../../common/models/emailTemplateDtoIEnumerableResponse';
import { ToasterService } from '@maersk-global/angular-shared-library';
import {
  AngularEditorConfig,
  AngularEditorModule,
} from '@kolkov/angular-editor';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { ClusterDto } from '../../common/models/clusterDto';
import { NotificationAppearance } from '@maersk-global/mds-components-core-notification/types';

@Component({
  selector: 'app-email-template-edit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AngularEditorModule],
  templateUrl: './email-template-edit.component.html',
  styleUrl: './email-template-edit.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EmailTemplateEditComponent implements OnInit {
  @ViewChild('emailBodyContainer') emailBodyContainer?: ElementRef;
  formGroup: FormGroup = new FormGroup({});
  frmTemplateName: FormGroup = new FormGroup({});
  selectedTemplate: EmailTemplateDto | undefined;
  updatingTemplate: boolean = false;
  useRichTextEditor: boolean = true;
  updatingTemplateName: boolean = false;
  authorized: boolean = false;
  loadingClusters: boolean = true;
  loadingTemplates: boolean = false;
  countryClusters: ClusterDto[] = [];
  selectedCluster: ClusterDto | undefined;
  emailTemplates: EmailTemplateDto[] = [];
  openModal: boolean = false;
  frmTemplateNameErrorMessage: string = '';
  frmTemplateNameError: boolean = false;
  notificationMessage: string = '';
  showNotification: boolean = false;
  notificationType: NotificationAppearance = 'neutral-weak';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'no',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: false,
    toolbarPosition: 'top',
  };

  /**
   *
   */
  constructor(
    private _cookieService: CookieService,
    private _toasterService: ToasterService,
    private _emailTemplateService: EmailTemplateService,
    private _commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (true) {
      this.authorized = true;
      this.formGroup = new FormGroup({
        cluster: new FormControl('', Validators.required),
        template: new FormControl('', Validators.required),
        subject: new FormControl({ value: '', disabled: true }),
        defaultButton: new FormControl({ value: '', disabled: true }),
        saveButton: new FormControl({ value: '', disabled: true }),
        htmlContent: new FormControl(''),
        editorMode: new FormControl({ value: '', disabled: true }),
        renameButton: new FormControl({ value: '', disabled: true }),
      });
      this.frmTemplateName = new FormGroup({
        newTemplateName: new FormControl(''),
      });
      this.countryClusters$?.subscribe((clusters) => {
        if (!clusters) return;
        this.countryClusters = clusters.sort((a, b) => {
          return a.clusterName!.localeCompare(b.clusterName!);
        });
        this.loadingClusters = false;
      });
      this.resetFormStates();
    } else {
      this.authorized = false;
      this.loadingClusters = false;
      this.loadingTemplates = false;
    }

    this.frmTemplateName.controls['newTemplateName'].valueChanges.subscribe(
      (newValue) => {
        if (!newValue) {
          this.frmTemplateNameError = true;
          this.frmTemplateNameErrorMessage = 'New name is required!';
          return;
        }

        if (newValue == this.selectedTemplate?.templateName) {
          this.frmTemplateNameError = true;
          this.frmTemplateNameErrorMessage = 'New name should be different!';
        } else {
          this.frmTemplateNameError = false;
          this.frmTemplateNameErrorMessage = '';
        }
      }
    );
  }

  /**
   *Main observable Country Clusters
   */
  countryClusters$?: Observable<ClusterDto[] | null> = this._commonService
    .commonCountryClustersGet()
    .pipe(
      map((response: ClusterDto[]) => {
        if (!response) return null;
        return response;
      }),
      shareReplay(1)
    );

  async clusterSelected(event: any) {
    const Id = event.detail.value;
    const clusters = await firstValueFrom(this.countryClusters$!);
    const selectedCluster = clusters?.find(
      (cluster) => cluster.clusterCode === Id
    );

    if (this.selectedCluster == selectedCluster) return;

    this.selectedCluster = selectedCluster;
    this.formGroup.controls['template'].setValue('');
    this.formGroup.controls['subject'].setValue('');
    this.selectedTemplate = undefined;
    this.resetFormStates();
    if (!this.selectedCluster) {
      this.emailTemplates = [];
      return;
    }
    this.loadingTemplates = true;
    // get email templates based on selected cluster
    this._emailTemplateService
      .emailTemplateCountryClusterCodeGet(this.selectedCluster!.clusterCode!)
      .pipe(
        map((response: EmailTemplateDtoIEnumerableResponse) => {
          if (!response || !response.isSuccess || !response.data) return null;
          return response.data;
        })
      )
      .subscribe(
        (templates) => {
          if (!templates) return;
          this.emailTemplates = templates;
          this.loadingTemplates = false;
        },
        (error) => {
          this.loadingTemplates = false;
          this._toasterService.showToast({
            message: 'Something went wrong while loading templates!',
            type: 'error',
          });
        }
      );
  }

  async templateSelected(event: any) {
    const Id = event.detail.value;
    const selectedTemplate = this.emailTemplates.find(
      (template) => template.id === Id
    );
    this.selectedTemplate = selectedTemplate;
    this.formGroup.controls['htmlContent'].setValue(
      this.selectedTemplate?.templateContentForDisplay
    );
    this.formGroup.controls['subject'].setValue(
      this.selectedTemplate?.templateSubject
    );
    this.resetFormStates();
  }

  ResetContentToDefault() {
    if (this.useRichTextEditor) {
      this.formGroup.controls['htmlContent'].setValue(
        this.selectedTemplate?.templateContentForDisplay
      );
    } else {
      this.emailBodyContainer!.nativeElement.innerHTML =
        this.selectedTemplate?.templateContentForDisplay || '';
    }
    this.formGroup.controls['subject'].setValue(
      this.selectedTemplate?.templateSubject
    );
  }

  private resetFormStates() {
    if (!this.selectedCluster) {
      this.formGroup.controls['template'].disable();
    } else {
      this.formGroup.controls['template'].enable();
    }

    if (!this.selectedTemplate) {
      this.formGroup.controls['defaultButton'].disable();
      this.formGroup.controls['saveButton'].disable();
      this.formGroup.controls['editorMode'].disable();
      this.formGroup.controls['subject'].disable();
      this.formGroup.controls['renameButton'].disable();
      return;
    }
    this.formGroup.controls['defaultButton'].enable();
    this.formGroup.controls['saveButton'].enable();
    this.formGroup.controls['editorMode'].enable();
    this.formGroup.controls['subject'].enable();
    this.formGroup.controls['renameButton'].enable();
  }

  changeEditorMode(event: any) {
    this.useRichTextEditor = event.detail;
  }

  SaveTemplateContent() {
    if (true) {
      const request = this.selectedTemplate;
      if (!this.detechChanges()) {
        return;
      }
      if (this.useRichTextEditor) {
        request!.templateContentForDisplay =
          this.formGroup.controls['htmlContent'].value;
      } else {
        request!.templateContentForDisplay =
          this.emailBodyContainer?.nativeElement.innerHTML;
      }
      request!.templateSubject = this.formGroup.controls['subject'].value;

      this.updatingTemplate = true;
      this.editorConfig.editable = false;
      this.updateTemplate(request, false);
    } else {
      this.authorized = false;
    }
  }

  updateTemplate(request: EmailTemplateDto | undefined, isRename?: boolean) {
    this._emailTemplateService.emailTemplatePut(request!).subscribe(
      () => {
        if (isRename) {
          this.updatingTemplateName = false;
          this.showNotification = true;
          this.notificationMessage = 'Template Renamed!';
          this.notificationType = 'success';
          return;
        }
        this.updatingTemplate = false;
        this.editorConfig.editable = true;
        this._toasterService.showToast({
          message: 'Template Updated!',
          type: 'success',
        });
      },
      (error) => {
        console.error(error);
        if (isRename) {
          this.updatingTemplateName = false;
          this.showNotification = true;
          this.notificationMessage = 'Something went wrong while renaming!';
          this.notificationType = 'error';
          return;
        }
        this.updatingTemplate = false;
        this.editorConfig.editable = true;
        this._toasterService.showToast({
          message: 'Something went wrong while updating template!',
          type: 'error',
        });
      }
    );
  }

  detechChanges(): boolean {
    let changsDetected = true;

    if (
      this.useRichTextEditor &&
      this.selectedTemplate?.templateContentForDisplay ==
        this.formGroup.controls['htmlContent'].value &&
      this.formGroup.controls['subject'].value.toString() ==
        this.selectedTemplate?.templateSubject.toString()
    ) {
      changsDetected = false;
    } else if (
      this.selectedTemplate?.templateContentForDisplay ==
        this.emailBodyContainer?.nativeElement.innerHTML &&
      this.formGroup.controls['subject'].value.toString() ==
        this.selectedTemplate?.templateSubject.toString()
    ) {
      changsDetected = false;
    }
    if (!changsDetected) {
      this._toasterService.showToast({
        message: 'No Changes detected!',
        type: 'info',
      });
    }
    return changsDetected;
  }

  toggleModal() {
    this.showNotification = false;
    this.notificationMessage = '';
    this.openModal = !this.openModal;
    if (
      this.openModal &&
      this.selectedTemplate &&
      this.selectedTemplate.globalDefault
    ) {
      this.notificationType = 'warning';
      this.notificationMessage =
        'This is a global default template. this change will be reflected in all clusters.';
      this.showNotification = true;
    }
  }

  onModelClose(_: any) {
    this.openModal = false;
    this.frmTemplateNameError = false;
    this.frmTemplateNameErrorMessage = '';
    this.showNotification = false;
    this.notificationMessage = '';
    this.frmTemplateName.reset();
  }

  renameTemplate() {
    const existingTemplatename = this.selectedTemplate?.templateName;
    if (!this.frmTemplateName.controls['newTemplateName'].value) {
      this.frmTemplateNameError = true;
      this.frmTemplateNameErrorMessage = 'New name is required!';
      return;
    }
    if (
      existingTemplatename ==
      this.frmTemplateName.controls['newTemplateName'].value
    ) {
      this.frmTemplateNameError = true;
      this.frmTemplateNameErrorMessage = 'New name should be different!';
      return;
    }
    const request = this.selectedTemplate;
    request!.templateName =
      this.frmTemplateName.controls['newTemplateName'].value;
    this.updatingTemplateName = true;
    this.updateTemplate(request, true);
  }
}
