import { Injectable } from '@angular/core';
import { Observable, merge, tap } from 'rxjs';
import { LiabilityLetterDto } from './common/models/liabilityLetterDto';
import { InvoicingStatus } from './common/models/invoicingStatus';
import { CustomerInfoDto } from './common/models/customerInfoDto';
import { LiablePartyDto } from './common/models/liabilePartyDto';
import { gcssBookingInfo } from './common/models/gcssBookingInfo';
import { CaseDamageDetailDto } from './common/models/caseDamageDetailDto';
import { CaseInvoiceDetailDto } from './common/models/caseInvoiceDetailDto';
import { ContainerMoveDto } from './common/models/containerMoveDto';
import { SharedCustomerRecoveryCaseService } from './components/customer-recovery/shared-customer-recovery-case.service';
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { FormValidation } from './common/models/formValidation';
import { SharedVendorRecoveryCaseService } from './components/vendor-recovery/shared-vendor-recovery-case.service';
import { RecoveryCase } from './common/models/recoveryCase';
import { RecoveryCaseService } from './common/services/recovery/recovery.service';

export interface ISharedRecoveryCaseService {
  reloadRecoveryCaseData(): void;
  reloadDamageDetails(): void;
  reloadLiabilityPartyDetails(): void;
  reloadLiabilityLetters(): void;
  reloadInvoices(): void;
  updateLiabilityDetailsVisibility(value: boolean): void;
  updateIssueInvoiceVisibility(value: boolean): void;
  updateEirImageLastFetchOn(date: Date | undefined): void;
  updateCustomerData(data: CustomerInfoDto): void;
  updateTabSelected(tabIndex: number): void;
  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ): void;
  updateCurrentStageId(stageId: number): void;
  updateFormValidationState(state: FormValidation): void;
}

@Injectable({
  providedIn: 'root',
})
export class SharedRecoveryCaseService {
  caseContext?: ISharedRecoveryCaseService;

  constructor(
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _vendorRecoverySharedService: SharedVendorRecoveryCaseService,
    private _recoveryClaimService: RecoveryCaseService
  ) {}

  recoveryCaseData$: Observable<CustomerRecoveryCaseDto | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryCaseData$.pipe(
      tap(() => {
        this.caseContext = this._customerRecoverySharedService;
      })
    )
    // this._vendorRecoverySharedService.vendorRecoveryCaseData$.pipe(
    //   tap(() => {
    //     this.caseContext = this._vendorRecoverySharedService;
    //   })
    // )
  );

  customerData$: Observable<CustomerInfoDto> = merge(
    this._customerRecoverySharedService.customerRecoveryCustomerData$
  );

  currentStageId$: Observable<number> = merge(
    this._customerRecoverySharedService.currentStageId$
  );

  eirImageLastFetchDate$: Observable<Date | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryEirImageLastFetchDate$
  );

  containerDetails$: Observable<ContainerMoveDto | null | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryContainerMovesDetails$
  );

  currentTabIndex$: Observable<number | 0> = merge(
    this._customerRecoverySharedService.currentCustomerRecoveryTabIndex$,
    this._vendorRecoverySharedService.currentVendorRecoveryTabIndex$
  );

  LiablePartyData$: Observable<LiablePartyDto | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiablePartyData$
  );

  liabilityLetters$: Observable<Array<LiabilityLetterDto> | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiabilityLetters$
  );

  shouldShowLiabilityDetails$: Observable<boolean> = merge(
    this._customerRecoverySharedService
      .shouldShowCustomerRecoveryLiabilityDetails$
  );

  invoices$: Observable<Array<CaseInvoiceDetailDto> | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryInvoices$
  );

  shouldShowIssueInvoiceScreen$: Observable<boolean> = merge(
    this._customerRecoverySharedService
      .shouldShowCustomerRecoveryIssueInvoiceScreen$
  );

  invoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = merge(
    this._customerRecoverySharedService
      .customerRecoveryInvoiceCreationPermission$
  );

  bookingCargoDetails$: Observable<gcssBookingInfo | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryBookingCargoDetails$
  );

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryDamageDetails$
  );

  reloadRecoveryCaseData = () => this.caseContext?.reloadRecoveryCaseData();

  reloadDamageDetails = () => this.caseContext?.reloadDamageDetails();

  reloadLiabilityPartyDetails = () =>
    this.caseContext?.reloadLiabilityPartyDetails();

  reloadLiabilityLetters = () => this.caseContext?.reloadLiabilityLetters();

  reloadInvoices = () => this.caseContext?.reloadInvoices();

  updateLiabilityDetailsVisibility = (value: boolean) =>
    this.caseContext?.updateLiabilityDetailsVisibility(value);

  updateIssueInvoiceVisibility = (value: boolean) =>
    this.caseContext?.updateIssueInvoiceVisibility(value);

  updateEirImageLastFetchOn = (date: Date | undefined) =>
    this.caseContext?.updateEirImageLastFetchOn(date);

  updateCustomerData = (data: CustomerInfoDto) =>
    this.caseContext?.updateCustomerData(data);

  updateTabSelected = (tabIndex: number) =>
    this.caseContext?.updateTabSelected(tabIndex);

  updateContainerDetails = (
    containerDetails: ContainerMoveDto | null | undefined
  ) => this.caseContext?.updateContainerDetails(containerDetails);

  updateCurrentStageId = (stageId: number) =>
    this.caseContext?.updateCurrentStageId(stageId);

  updateFormValidationState = (state: FormValidation) =>
    this.caseContext?.updateFormValidationState(state);
}
