/**
 * DCRP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { SortOrder } from '../../models/sortOrder';
import { RecoveryCaseStatus } from '../../models/recoveryCaseStatus';
import { RecoveryCaseIEnumerableResponse } from '../../models/recoveryCaseIEnumerableResponse';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RecoveryCaseType } from '../../models/recoveryCaseType';
import { Int32Response } from '../../models/int32Response';
import { RecoveryRequest } from '../../models/recovery-request';

@Injectable()
export class RecoveryCaseService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * This API will fetch count of recovery cases based on the recovery type and other filters
   *
   * @param recoveryCaseType
   * @param paginationPageNumber
   * @param paginationPageSize
   * @param paginationSortOrder
   * @param paginationSortColumn
   * @param caseId
   * @param caseStatuses
   * @param equipmentNumber
   * @param invoiceNumber
   * @param workOrderNumber
   * @param bookingNumber
   * @param podClusters
   * @param podCountries
   * @param dateRangeFromDateTime
   * @param dateRangeToDateTime
   * @param assignedTo
   * @param shopCountryCode
   * @param podRegions
   * @param shopCodes
   * @param createdBy
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recoveryCasesCountGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Int32Response>;
  public recoveryCasesCountGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Int32Response>>;
  public recoveryCasesCountGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Int32Response>>;
  public recoveryCasesCountGet(
    recoveryRequest: RecoveryRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      recoveryRequest.recoveryCaseType === null ||
      recoveryRequest.recoveryCaseType === undefined
    ) {
      throw new Error(
        'Required parameter recoveryCaseType was null or undefined when calling recoveryCasesGet.'
      );
    }

    let queryParameters = new HttpParams();
    if (
      recoveryRequest.recoveryCaseType !== undefined &&
      recoveryRequest.recoveryCaseType !== null
    ) {
      queryParameters = queryParameters.set(
        'RecoveryCaseType',
        <any>recoveryRequest.recoveryCaseType
      );
    }
    if (
      recoveryRequest.paginationPageNumber !== undefined &&
      recoveryRequest.paginationPageNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.PageNumber',
        <any>recoveryRequest.paginationPageNumber
      );
    }
    if (
      recoveryRequest.paginationPageSize !== undefined &&
      recoveryRequest.paginationPageSize !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.PageSize',
        <any>recoveryRequest.paginationPageSize
      );
    }
    if (
      recoveryRequest.paginationSortOrder !== undefined &&
      recoveryRequest.paginationSortOrder !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.SortOrder',
        <any>recoveryRequest.paginationSortOrder
      );
    }
    if (
      recoveryRequest.paginationSortColumn !== undefined &&
      recoveryRequest.paginationSortColumn !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.SortColumn',
        <any>recoveryRequest.paginationSortColumn
      );
    }
    if (
      recoveryRequest.caseId !== undefined &&
      recoveryRequest.caseId !== null
    ) {
      queryParameters = queryParameters.set(
        'CaseId',
        <any>recoveryRequest.caseId
      );
    }
    if (recoveryRequest.caseStatuses) {
      recoveryRequest.caseStatuses.forEach((element) => {
        queryParameters = queryParameters.append('CaseStatuses', <any>element);
      });
    }
    if (
      recoveryRequest.equipmentNumber !== undefined &&
      recoveryRequest.equipmentNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'EquipmentNumber',
        <any>recoveryRequest.equipmentNumber
      );
    }
    if (
      recoveryRequest.invoiceNumber !== undefined &&
      recoveryRequest.invoiceNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'InvoiceNumber',
        <any>recoveryRequest.invoiceNumber
      );
    }
    if (
      recoveryRequest.workOrderNumber !== undefined &&
      recoveryRequest.workOrderNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'WorkOrderNumber',
        <any>recoveryRequest.workOrderNumber
      );
    }
    if (
      recoveryRequest.bookingNumber !== undefined &&
      recoveryRequest.bookingNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'BookingNumber',
        <any>recoveryRequest.bookingNumber
      );
    }
    if (recoveryRequest.podClusters) {
      recoveryRequest.podClusters.forEach((element) => {
        queryParameters = queryParameters.append('PodClusters', <any>element);
      });
    }
    if (recoveryRequest.podCountries) {
      recoveryRequest.podCountries.forEach((element) => {
        queryParameters = queryParameters.append('PodCountries', <any>element);
      });
    }
    if (
      recoveryRequest.dateRangeFromDateTime !== undefined &&
      recoveryRequest.dateRangeFromDateTime !== null
    ) {
      queryParameters = queryParameters.set(
        'DateRange.FromDateTime',
        <any>recoveryRequest.dateRangeFromDateTime.toISOString()
      );
    }
    if (
      recoveryRequest.dateRangeToDateTime !== undefined &&
      recoveryRequest.dateRangeToDateTime !== null
    ) {
      queryParameters = queryParameters.set(
        'DateRange.ToDateTime',
        <any>recoveryRequest.dateRangeToDateTime.toISOString()
      );
    }
    if (
      recoveryRequest.assignedTo !== undefined &&
      recoveryRequest.assignedTo !== null
    ) {
      queryParameters = queryParameters.set(
        'AssignedTo',
        <any>recoveryRequest.assignedTo
      );
    }
    if (recoveryRequest.shopCountryCode) {
      recoveryRequest.shopCountryCode.forEach((element) => {
        queryParameters = queryParameters.append(
          'ShopCountryCode',
          <any>element
        );
      });
    }
    if (recoveryRequest.podRegions) {
      recoveryRequest.podRegions.forEach((element) => {
        queryParameters = queryParameters.append('PodRegions', <any>element);
      });
    }
    if (recoveryRequest.shopCodes) {
      recoveryRequest.shopCodes.forEach((element) => {
        queryParameters = queryParameters.append('ShopCodes', <any>element);
      });
    }
    if (recoveryRequest.createdBy) {
      recoveryRequest.createdBy.forEach((element) => {
        queryParameters = queryParameters.append('CreatedBy', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Int32Response>(
      'get',
      `${this.basePath}/recovery-cases/count`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This API will fetch list of recovery cases based on the recovery type and other filters
   *
   * @param recoveryCaseType
   * @param paginationPageNumber
   * @param paginationPageSize
   * @param paginationSortOrder
   * @param paginationSortColumn
   * @param caseId
   * @param caseStatuses
   * @param equipmentNumber
   * @param invoiceNumber
   * @param workOrderNumber
   * @param bookingNumber
   * @param podClusters
   * @param podCountries
   * @param dateRangeFromDateTime
   * @param dateRangeToDateTime
   * @param assignedTo
   * @param shopCountryCode
   * @param podRegions
   * @param shopCodes
   * @param createdBy
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public recoveryCasesGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<RecoveryCaseIEnumerableResponse>;
  public recoveryCasesGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<RecoveryCaseIEnumerableResponse>>;
  public recoveryCasesGet(
    recoveryRequest: RecoveryRequest,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<RecoveryCaseIEnumerableResponse>>;
  public recoveryCasesGet(
    recoveryRequest: RecoveryRequest,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (
      recoveryRequest.recoveryCaseType === null ||
      recoveryRequest.recoveryCaseType === undefined
    ) {
      throw new Error(
        'Required parameter recoveryCaseType was null or undefined when calling recoveryCasesGet.'
      );
    }

    let queryParameters = new HttpParams();
    if (
      recoveryRequest.recoveryCaseType !== undefined &&
      recoveryRequest.recoveryCaseType !== null
    ) {
      queryParameters = queryParameters.set(
        'RecoveryCaseType',
        <any>recoveryRequest.recoveryCaseType
      );
    }
    if (
      recoveryRequest.paginationPageNumber !== undefined &&
      recoveryRequest.paginationPageNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.PageNumber',
        <any>recoveryRequest.paginationPageNumber
      );
    }
    if (
      recoveryRequest.paginationPageSize !== undefined &&
      recoveryRequest.paginationPageSize !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.PageSize',
        <any>recoveryRequest.paginationPageSize
      );
    }
    if (
      recoveryRequest.paginationSortOrder !== undefined &&
      recoveryRequest.paginationSortOrder !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.SortOrder',
        <any>recoveryRequest.paginationSortOrder
      );
    }
    if (
      recoveryRequest.paginationSortColumn !== undefined &&
      recoveryRequest.paginationSortColumn !== null
    ) {
      queryParameters = queryParameters.set(
        'Pagination.SortColumn',
        <any>recoveryRequest.paginationSortColumn
      );
    }
    if (
      recoveryRequest.caseId !== undefined &&
      recoveryRequest.caseId !== null
    ) {
      queryParameters = queryParameters.set(
        'CaseId',
        <any>recoveryRequest.caseId
      );
    }
    if (recoveryRequest.caseStatuses) {
      recoveryRequest.caseStatuses.forEach((element) => {
        queryParameters = queryParameters.append('CaseStatuses', <any>element);
      });
    }
    if (
      recoveryRequest.equipmentNumber !== undefined &&
      recoveryRequest.equipmentNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'EquipmentNumber',
        <any>recoveryRequest.equipmentNumber
      );
    }
    if (
      recoveryRequest.invoiceNumber !== undefined &&
      recoveryRequest.invoiceNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'InvoiceNumber',
        <any>recoveryRequest.invoiceNumber
      );
    }
    if (
      recoveryRequest.workOrderNumber !== undefined &&
      recoveryRequest.workOrderNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'WorkOrderNumber',
        <any>recoveryRequest.workOrderNumber
      );
    }
    if (
      recoveryRequest.bookingNumber !== undefined &&
      recoveryRequest.bookingNumber !== null
    ) {
      queryParameters = queryParameters.set(
        'BookingNumber',
        <any>recoveryRequest.bookingNumber
      );
    }
    if (recoveryRequest.podClusters) {
      recoveryRequest.podClusters.forEach((element) => {
        queryParameters = queryParameters.append('PodClusters', <any>element);
      });
    }
    if (recoveryRequest.podCountries) {
      recoveryRequest.podCountries.forEach((element) => {
        queryParameters = queryParameters.append('PodCountries', <any>element);
      });
    }
    if (
      recoveryRequest.dateRangeFromDateTime !== undefined &&
      recoveryRequest.dateRangeFromDateTime !== null
    ) {
      queryParameters = queryParameters.set(
        'DateRange.FromDateTime',
        <any>recoveryRequest.dateRangeFromDateTime.toISOString()
      );
    }
    if (
      recoveryRequest.dateRangeToDateTime !== undefined &&
      recoveryRequest.dateRangeToDateTime !== null
    ) {
      queryParameters = queryParameters.set(
        'DateRange.ToDateTime',
        <any>recoveryRequest.dateRangeToDateTime.toISOString()
      );
    }
    if (
      recoveryRequest.assignedTo !== undefined &&
      recoveryRequest.assignedTo !== null
    ) {
      queryParameters = queryParameters.set(
        'AssignedTo',
        <any>recoveryRequest.assignedTo
      );
    }
    if (recoveryRequest.shopCountryCode) {
      recoveryRequest.shopCountryCode.forEach((element) => {
        queryParameters = queryParameters.append(
          'ShopCountryCode',
          <any>element
        );
      });
    }
    if (recoveryRequest.podRegions) {
      recoveryRequest.podRegions.forEach((element) => {
        queryParameters = queryParameters.append('PodRegions', <any>element);
      });
    }
    if (recoveryRequest.shopCodes) {
      recoveryRequest.shopCodes.forEach((element) => {
        queryParameters = queryParameters.append('ShopCodes', <any>element);
      });
    }
    if (recoveryRequest.createdBy) {
      recoveryRequest.createdBy.forEach((element) => {
        queryParameters = queryParameters.append('CreatedBy', <any>element);
      });
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];

    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<RecoveryCaseIEnumerableResponse>(
      'get',
      `${this.basePath}/recovery-cases`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
