import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ALIGN,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { CaseActivityLogsDto } from '../../../common/models/caseActivityLogsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';

@Component({
  selector: 'activity-log',
  standalone: true,
  imports: [CommonModule, GridComponent],
  templateUrl: './activity-log.component.html',
  styleUrl: './activity-log.component.scss',
})
export class ActivityLogComponent {
  @Input({ required: true }) item?: TemplateModel;

  apiVersion: string = '1.0';

  /**
   * Activity log grid Schema.
   */
  activityLogGridSchema: GridColumnSchema[] = [];

  /**
   * activity log records and all associated case with which we receive from API.
   */
  logs$?: Observable<CaseActivityLogsDto[] | null>;

  /**
   * activity log grid data
   */
  activityLogGridData$?: Observable<GridRowData[] | null>;

  caseDetails!: CustomerRecoveryCaseDto;
  constructor(
    private _customerRecoveryClaimService: CustomerRecoveryClaimService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService
  ) {}

  customerRecoveryData$: Observable<CustomerRecoveryCaseDto | undefined> =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((recoveryData) => {
        this.caseDetails = recoveryData!;
        this.loadActivityDetails();
      })
    );

  loadActivityDetails(): void {
    this.activityLogGridData$ = this._customerRecoveryClaimService
      .customerRecoveryClaimsActivityLogsCaseIdGet(
        this.caseDetails.caseId ?? 0,
        this.apiVersion
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data.map((log) =>
            this.generateGridDataForActivityLog(log)
          );
        }),
        shareReplay(1)
      );
  }

  /**
   * We are creating a schema for the activity log grid.
   * @returns nothing
   */

  ngOnInit(): void {
    if (!this.item || !this.item.items) return;
    this.activityLogGridSchema = this.item.items.map((y: TemplateModel) => {
      const column = {
        column: y.name,
        displayName: y.label,
        align: ALIGN.LEFT,
        hidden: false,
        sequence: y.sequence,
        columnType: y.valueType?.toUpperCase(),
        dateFormat: 'd MMM y h:mm:ss a',
        disableSort: true,
      } as GridColumnSchema;
      return column;
    });
  }

  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param CaseActivityLogsDto original object received from web-api response
   * @returns grid row object created
   */
  private generateGridDataForActivityLog(
    caseLogs: CaseActivityLogsDto
  ): GridRowData {
    const logsKeyValue = caseLogs as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(caseLogs).map((key) => {
      gridRowObject[key] = {
        value: logsKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
    } as GridRowData;
  }
}
