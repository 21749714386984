export const environment = {
  production: false,
  environment: 'staging',

  //configure API
  url: 'TO_BE_ADDED',
  dcrp_api_base_url: 'https://api-stage.maersk.com/ccdcrp',
  workflow_api_base_url: 'https://wapazewsmlit016workflowapi.azurewebsites.net',
  ccdcrp_api_base_url: 'https://fleet-dcrp-stg.maersk-digital.net',
  authority: 'https://accounts-stage.maersk.com/thirdparty',
  clientId: 'thirdparty',
  postLoginRedirectUri: 'https://ccdcrp-stage.maersk.com/oauth2/login',
  consumer_key: 'WpIWnvqshoqbZo4K7nv6WY5eaSy30sYT',
  inactivityLogoutSeconds: 1800, // 30 Mins
  refreshTokenBeforeSeconds: 900, // 15 Mins before token expiry
  isSiteUndermaintenance: false,
  localTemplate: true,
  enableCaseWorkflow: true,
  disableFileUpload: false,
  sessionTimeoutTime: 119 * 60 * 1000, // 30 minutes
  searchIntervalForManualCase: 90,
  searchIntervalForContainerMoves: 15,
  api_key_rum:
    'BCYSN/YOICUhkviXRSECpOcQWMu+Gj24blGptxqqu0g7g9pEIIFTQfZPEmF8Eg==',
  FAROENVIRONMENT: 'stage',
  IN_SIGHT_FEEDBACK_ID: '4b8ba5f5-bea7-4b8b-9391-99bca83881e3',
};
