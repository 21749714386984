import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import {
  ALIGN,
  CellClickEvent,
  COLUMN_CLICK_TYPE,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
  LibFormComponent,
  NoDataComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
import { DamageType } from '../../../common/models/damageType';
import { CaseService } from '../../../common/services/case/case.service';
import { RepairLineItemRequest } from '../../../common/models/repairLineItemRequest';
import { RepairLineItem } from '../../../common/models/repairLineItem';
import { divisionLoaderComponent } from './section-skeleton-loader.component';
import { DAMAGE_ESTIMATION_USE_NOTIFICATION_MSG } from '../../../common/constants/app.constants';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
import { DcrpAuthorizationService } from '../../../common/services/authorization/dcrp-authorization.service';
@Component({
  selector: 'manual-estimates',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    GridComponent,
    divisionLoaderComponent,
    SectionSkeletonLoaderComponent,
    NoDataComponent,
  ],
  templateUrl: './manual-estimates.component.html',
  styleUrl: './manual-estimates.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManualEstimatesComponent {
  @Input() item!: TemplateModel;
  disable: boolean = false;

  damageEstimationNotificationMsg: string =
    DAMAGE_ESTIMATION_USE_NOTIFICATION_MSG;
  materialCode: string = '';
  lineItemsGridSchema: GridColumnSchema[] = [];
  lineItemsGridData: GridRowData | undefined;
  damageTypeLoaded: boolean = false;
  customerRecoveryDto: CustomerRecoveryCaseDto | undefined;
  items: TemplateModel[] = [];
  showButtonLoader: boolean = false;
  showGridLoader: boolean = false;
  showControls: boolean = true;

  private currentSelectedDamageType$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private currentSelectedDamageAreaType$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private currentSelectedDamageSize$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private reloadGridCounter$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  damageTypesConfig$ = this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
    switchMap((customerRecovery) => {
      this.customerRecoveryDto = customerRecovery;
      return this._commonService.commonDamageTypesGet().pipe(
        map((response) => response.data),
        shareReplay(1)
      );
    })
  );

  lineItemsGridData$: Observable<GridRowData[]> = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this.reloadGridCounter$$,
  ]).pipe(
    switchMap(([recoveryData, counter]) => {
      this.showGridLoader = true;
      this.showButtonLoader = false;
      this.customerRecoveryDto = recoveryData;

      return this._caseService
        .customerRecoveryClaimsCaseIdRepairLineItemsGet(recoveryData?.caseId!)
        .pipe(
          map((response) => {
            this.showButtonLoader = false;
            this._customerRecoverySharedService.updateManualEstimatesLineItems(
              response.data
            );
            return response.data && response.data.length > 0
              ? response.data.map((item) =>
                  this.generateGridDataFromLineItems(item)
                )
              : [];
          }),
          tap((_) => {
            this.showGridLoader = false;
          })
        );
    })
  );

  templateModel$ = combineLatest([
    this.damageTypesConfig$,
    this.currentSelectedDamageType$$,
    this.currentSelectedDamageAreaType$$,
    this.currentSelectedDamageSize$$,
    this._customerRecoverySharedService.disableForm$,
  ]).pipe(
    map(([damageTypesDataset, damageType, areaType, size, disable]) => {
      this.disable = disable;
      if (!damageTypesDataset) return;
      this.loadLineItemsGridSchema();
      this.item.items?.forEach((item) => {
        this.updateLabelWithCurrencyPlaceholder(item);
        this.updateItem(item, damageTypesDataset, damageType, areaType);
      });

      return this.item.items;
    })
  );

  constructor(
    private _toasterService: ToasterService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _caseService: CaseService,
    private _commonService: CommonService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _dcrpAuthorizationService: DcrpAuthorizationService
  ) {}

  async addManualEstimates(): Promise<void> {
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const req = this.getLineItemPostReq(userId);

    if (
      (req && req.quantity && req.quantity < 0) ||
      (req.unitRepairCostLocal && req.unitRepairCostLocal < 0)
    ) {
      this._toasterService.showToast({
        message: 'Negative values not allowed',
        type: 'warning',
      });

      return;
    }

    this.showButtonLoader = true;
    this.showControls = false;

    const _ = lastValueFrom(
      this._caseService.customerRecoveryClaimsCaseIdRepairLineItemPost(
        this.customerRecoveryDto?.caseId!,
        req
      )
    )
      .then((_) => {
        this.showGridLoader = true;
        this.showControls = true;
        this.reloadGridCounter$$.next(this.reloadGridCounter$$.value + 1);
        this.resetItems();
      })
      .catch((err) => {
        this.showButtonLoader = false;
        this.showControls = true;
        if (err?.status == 400) {
          this._toasterService.showToast({
            message: 'Invalid input provided!',
            type: 'warning',
          });
        }
      })
      .finally(() => {
        this.showButtonLoader = false;
      });
  }

  private getLineItemPostReq(userId: string): RepairLineItemRequest {
    const reqObj: RepairLineItemRequest = {};

    this.item.items?.forEach((el) => {
      switch (el.name) {
        case 'damageType':
          reqObj.damageType = el.value;
          break;
        case 'area':
          reqObj.area = el.value;
          break;
        case 'damageSize':
          reqObj.size = el.value;
          break;
        case 'unitRepairCost':
          reqObj.unitRepairCostLocal = el.value;
          break;
        case 'quantity':
          reqObj.quantity = el.value;
          break;
      }
    });

    if (
      this.customerRecoveryDto &&
      !this.customerRecoveryDto.exchangeRateUSDCaseCurrency &&
      this.customerRecoveryDto?.caseCurrency == 'USD'
    ) {
      this.customerRecoveryDto!.exchangeRateUSDCaseCurrency = 1;
    }
    reqObj.materialCode = this.materialCode;
    reqObj.userId = userId;
    reqObj.lineTotalLocal = reqObj.quantity! * reqObj.unitRepairCostLocal!;
    reqObj.localCurrency = this.customerRecoveryDto?.caseCurrency;
    reqObj.lineTotalUSD =
      Math.round(
        reqObj.lineTotalLocal! *
          100 *
          (this.customerRecoveryDto?.exchangeRateUSDCaseCurrency! ?? 1)
      ) / 100;

    return reqObj;
  }

  private loadLineItemsGridSchema() {
    if (!this.item || !this.item.items) return;

    const manualEstimatesLineItems = this.item.items.filter(
      (o) => o.name == 'manualEstimatesLineItems'
    )[0];

    if (
      !manualEstimatesLineItems ||
      !manualEstimatesLineItems.items ||
      manualEstimatesLineItems.items.length === 0
    )
      return;

    this.lineItemsGridSchema = manualEstimatesLineItems.items.map(
      (y: TemplateModel) => {
        this.updateLabelWithCurrencyPlaceholder(y);

        let column = {
          column: y.name,
          displayName: y.label,
          align: ALIGN.LEFT,
          hidden: this.hideColumnVisibility(y.name, y.hidden),
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          disableSort: true,
          icon: y.icon,
          isClickable: y.icon && !this.disable ? true : false,
          columClickType: y.icon ? COLUMN_CLICK_TYPE.ICON_CLICK : undefined,
          onClick: y.icon ? this.onLineItemDelete.bind(this) : undefined,
        } as GridColumnSchema;

        return column;
      }
    );
  }

  private hideColumnVisibility(
    columnName: string,
    defaultHiddenVisibility: boolean | undefined
  ) {
    if (defaultHiddenVisibility) return true;
    else {
      if (
        columnName == 'lineTotalLocal' &&
        this.customerRecoveryDto?.caseCurrency?.toUpperCase() == 'USD'
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  private onLineItemDelete(cellData: CellClickEvent) {
    if (!this.customerRecoveryDto) {
      return;
    }

    const itemId: number = cellData.rowData
      ? Number(cellData.rowData['repairLineItemId'].value)
      : 0;

    if (itemId > 0) {
      lastValueFrom(
        this._caseService.customerRecoveryClaimsCaseIdRepairLineItemsRepairLineItemIdDelete(
          this.customerRecoveryDto.caseId!,
          itemId
        )
      ).then((_) => {
        this.reloadGridCounter$$.next(this.reloadGridCounter$$.value + 1);
      });
    }
  }

  private generateGridDataFromLineItems(lineItem: RepairLineItem): GridRowData {
    const lineItemKeyValue = lineItem as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(lineItem).map((key) => {
      gridRowObject[key] = {
        value: lineItemKeyValue[key],
      } as GridCellData;
    });
    return {
      row: gridRowObject,
    } as GridRowData;
  }

  private updateMaterialCode(
    damageTypeDataset: DamageType[],
    damageTypeDesc: string,
    areaTypeDesc: string,
    sizeDesc: string
  ) {
    const filterDamageType = damageTypeDataset?.filter(
      (o) =>
        o.damageCodeDesc == damageTypeDesc &&
        o.areaDesc == areaTypeDesc &&
        o.sizeDesc == sizeDesc &&
        o.equipmentType == (this.customerRecoveryDto?.equipmentSubType ?? '')
    );

    if (filterDamageType?.length > 1) {
      this.materialCode = filterDamageType[1].factMaterialCode ?? '';
    } else if (filterDamageType?.length > 0) {
      this.materialCode = filterDamageType[0].factMaterialCode ?? '';
    }
  }

  private resetItems() {
    this.showControls = true;
    this.currentSelectedDamageType$$.next('');

    const resettableFields = ['quantity', 'unitRepairCost'];

    this.item.items?.forEach((el) => {
      if (resettableFields.includes(el.name)) {
        el.value = '';
      }
    });
  }

  private updateLabelWithCurrencyPlaceholder(item: TemplateModel) {
    if (!item.label?.includes('$currency')) return;

    item.label = item.label?.replace(
      '$currency',
      this.customerRecoveryDto?.caseCurrency!
    );
  }

  private updateItem(
    item: TemplateModel,
    damageTypesDataset: DamageType[],
    damageType: string,
    areaType: string
  ) {
    item.disabled = this.disable;
    switch (item.name) {
      case 'damageType':
        this.updateDamageType(item, damageTypesDataset, damageType);
        break;

      case 'area':
        this.updateArea(item, damageTypesDataset, damageType);
        break;

      case 'damageSize':
        this.updateDamageSize(item, damageTypesDataset, damageType, areaType);
        break;

      case 'unitRepairCost':
        this.updateRepairCost(item);
        break;

      default:
        break;
    }
  }

  private updateDamageType(
    item: TemplateModel,
    damageTypesDataset: DamageType[],
    damageType: string
  ) {
    if (!damageType) {
      this.damageTypeLoaded = true;
      item.options = this.getDamageTypes(damageTypesDataset);
      this.resetSelectListValueBasedOnTemplateName('damageType');
      item.onValueChanged = (value) => {
        this.currentSelectedDamageType$$.next(value);
        this.resetSelectListValueBasedOnTemplateName('area');
        this.resetSelectListValueBasedOnTemplateName('damageSize');
      };
    }
  }

  private updateArea(
    item: TemplateModel,
    damageTypesDataset: DamageType[],
    damageType: string
  ) {
    item.options = damageType
      ? this.getDamageAreaBasedOnDamageCode(damageTypesDataset, damageType)
      : [];
    item.onValueChanged = (value) => {
      this.resetSelectListValueBasedOnTemplateName('damageSize');
      this.currentSelectedDamageAreaType$$.next(value);
    };
  }

  private updateDamageSize(
    item: TemplateModel,
    damageTypesDataset: DamageType[],
    damageType: string,
    areaType: string
  ) {
    item.options =
      areaType && damageType
        ? this.getDamageSizeBasedOnDamageCodeAndArea(
            damageTypesDataset,
            damageType,
            areaType
          )
        : [];
    item.onValueChanged = (value) => {
      this.updateMaterialCode(damageTypesDataset, damageType, areaType, value);
      this.currentSelectedDamageSize$$.next(value);
    };
  }

  private updateRepairCost(item: TemplateModel) {
    item.label = item.label?.replace(
      '$currency',
      this.customerRecoveryDto?.caseCurrency!
    );
  }

  private resetSelectListValueBasedOnTemplateName(name: string) {
    this.item.items?.forEach((o) => {
      if (o.name == 'area' && o.name == name) {
        o.value = null;
      } else if (o.name == 'damageSize' && o.name == name) {
        o.value = null;
      } else if (o.name == 'damageType' && o.name == name) {
        o.value = null;
      }
    });
  }

  private getDamageTypes(damageTypes: DamageType[] | undefined) {
    if (!damageTypes) return;

    const uniqueDamageTypes = new Set<string>(); // To keep track of unique damageCodes

    const options = damageTypes?.reduce((acc: DropDownOption[], damageType) => {
      if (!uniqueDamageTypes.has(damageType.damageCode!)) {
        uniqueDamageTypes.add(damageType.damageCode!); // Add unique damageCode to the set
        acc.push({
          name: damageType.damageCode,
          label: damageType.damageCodeDesc,
          value: damageType.damageCodeDesc,
        });
      }
      return acc;
    }, []);

    return options;
  }

  private getDamageAreaBasedOnDamageCode(
    damageTypes: DamageType[] | undefined,
    damage: string
  ) {
    if (!damageTypes) return;

    return damageTypes
      ?.filter((o) => o.damageCodeDesc == damage)
      .map((o) => {
        return {
          label: o.areaDesc ?? '',
          name: o.areaCode,
          value: o.areaDesc,
        } as DropDownOption;
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      ) // Remove duplicates by label
      .sort((a, b) => a.label!.localeCompare(b.label!)); // Sort by label;
  }

  private getDamageSizeBasedOnDamageCodeAndArea(
    combinedData: DamageType[] | undefined,
    damage: string,
    area: string
  ) {
    if (!combinedData) return;

    return combinedData
      ?.filter((o) => o.damageCodeDesc == damage && o.areaDesc == area)
      .map((o) => {
        return {
          label: o.sizeDesc ?? '',
          name: o.sizeDesc,
          value: o.sizeDesc,
        } as DropDownOption;
      })
      .filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.label === value.label)
      ) // Remove duplicates by label
      .sort((a, b) => a.label!.localeCompare(b.label!)); // Sort by label;
  }
}
