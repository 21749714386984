/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import { MdsConfig } from '@maersk-global/mds-config';
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { environment } from './environments/environment';
MdsConfig.iconsDynamicImportPath = `${location.protocol}//${location.host}/maersk_assets/`;

export const faro = RumInit({
  app: {
    name: `dcrp_${environment.FAROENVIRONMENT}`,
    version: '1.0.0',
    environment: environment.FAROENVIRONMENT,
  },
  apiKey: environment.api_key_rum,
  debug: false,
  traceLog: true,
  useSendBeacon: true,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
