export default Object.freeze({
  extraPolicies: [
    {
      policyName: 'assign-case',
      roles: ['Admin', 'Specialist', 'Manager'],
    },
    {
      policyName: 'unassign-case',
      roles: ['Admin', 'Specialist', 'Manager'],
    },
    {
      policyName: 'close-case',
      roles: ['Admin', 'Specialist', 'Manager'],
    },
    {
      policyName: 'reopen-case',
      roles: ['Admin', 'Specialist', 'Manager'],
    },
  ],
});
