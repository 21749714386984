import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import {
  CellClickEvent,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridFilter,
  GridRowData,
  SizeUnit,
  SurveyComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { CustomerRecoveryClaimService } from '../../common/services/customer-recovery/customer-recovery-claim.service';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../common/services/customer-recovery/common.service';
import { StatusModalComponent } from './status-modal/status-modal.component';
import { ClaimStatusRequest } from '../../common/models/claim-status-request';
import { ClaimStatus } from '../../common/enum/claim-status';
import { ClaimStatusResponse } from '../../common/models/claim-status-response';
import { StatusDetailModel } from '../../common/models/status-detail';
import { UserClaimsAssignmentRequest } from '../../common/models/user-claims-assignment-request';
import { UserPreferencesService } from '../../common/services/user-preferences/user-preferences.service';
import { CustomerRecoveryDataManager } from './customer-recovery-data-manager';
import { WorkflowService } from '../../common/services/customer-recovery/workflow.service';
import { Router } from '@angular/router';
import { Observable, catchError, filter, firstValueFrom, tap } from 'rxjs';
import {
  statusTypeMaster,
  workflowStages,
} from '../../common/constants/temporary-constant';
import { UserClaimsUnassignmentRequest } from '../../common/models/user-claims-unassignment-request';
import { WorkOrderAndLineItemsService } from '../../common/services/customer-recovery/work-order-and-line-items.service';
import { SharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { CreateManualCaseComponent } from '../custom-workflow/create-manual-case/create-manual-case.component';
import { environment } from '../../../environments/environment';
import { SharedDataService } from '../../shared-data-service';
import { IsAuthorizedForDirective } from '../../common/directives/is-authorized-for.directive';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';

@Component({
  selector: 'customer-recovery',
  standalone: true,
  imports: [
    GridComponent,
    CommonModule,
    StatusModalComponent,
    CreateManualCaseComponent,
    SurveyComponent,
    IsAuthorizedForDirective,
  ],
  templateUrl: './customer-recovery.component.html',
  styleUrl: './customer-recovery.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CustomerRecoveryComponent implements OnInit {
  /**
   * Height is required to show scrollbars for the grid. If not provided, grid height will be 100% (i.e. it will wrap all records init).
   */
  height: SizeUnit = {
    size: 70,
    unit: 'vh',
  };

  /**
   * Local variables required for the component.
   */
  showFilter: boolean = false;
  statusDetails!: StatusDetailModel;
  IsStatusOrCommentModalVisible: boolean = false;
  claimStatusRequest: ClaimStatusRequest | undefined;
  showRowSelector: boolean = true;
  showLoader: boolean = true;
  selectedRowsOfGrid?: [{ [key: string]: unknown }];
  IsNewCaseModalVisible: boolean = false;
  /**
   * data manager acts as a view-model. We wanted to keep only UI specific logic here in component and all data/model related operations are moved in data manager.
   */
  dataManager: CustomerRecoveryDataManager;
  /**
   * grid data that is shown in the grid
   */
  gridData$: Observable<GridRowData[] | null>;
  /**
   * grid schema that is prepared based on user preferences.
   */
  gridSchema$: Observable<GridColumnSchema[] | null>;
  /**
   * Total records observable for the current customer recovery claim records in grid.
   */
  totalRecords$?: Observable<number>;

  gridFilter: GridFilter;

  emailID: string = sessionStorage.getItem('email')?.toString() ?? '';
  feedBackId: string = environment.IN_SIGHT_FEEDBACK_ID;

  constructor(
    _customerRecoveryClaimService: CustomerRecoveryClaimService,
    _userPreferencesService: UserPreferencesService,
    private _toaster: ToasterService,
    private _workflowService: WorkflowService,
    private _commonService: CommonService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _router: Router
  ) {
    this.dataManager = new CustomerRecoveryDataManager(
      _customerRecoveryClaimService,
      _userPreferencesService,
      _workflowService,
      _commonService,
      _sharedRecoveryCaseService,
      _sharedDataService,
      _dcrpAuthorizationService
    );
    this.gridSchema$ = this.dataManager.gridSchema$;
    this.dataManager.onGridColumnClick = this.onGridColumnClicked.bind(this);

    this.gridData$ = this.dataManager.gridData$.pipe(
      catchError((_) => {
        this.showLoader = false;
        this.updateGridWithNewData(true);
        throw 'Error while getting grid data from server.';
      }),
      // This filter makes sure when `gridData$` is empty (i.e. empty array mostly emitted by `gridDataSubject$$`), then table-skeleton is loaded
      // and when `gridData$` is `not empty` or `null` (i.e. API response data) then code flow goes ahead to stop loader and show data in grid.
      filter((value) => value?.length !== 0),
      tap((_) => {
        this.showLoader = false;
      })
    );
    this.totalRecords$ = this.dataManager.totalRecords$;
    this.gridFilter = this.dataManager.gridFilter;
  }

  tabIndex$!: Observable<number>;
  //to invoke this change only while we navigate via link from workflow
  componentInitiated: boolean = false;
  ngOnInit(): void {
    this.tabIndex$ = this._sharedRecoveryCaseService.currentTabIndex$.pipe(
      tap(async (tabIndex) => {
        if (tabIndex && !this.componentInitiated) {
          //only on load.
          await this.loadGridBasedOnCurrentTab(tabIndex);
          this.componentInitiated = true;
        }
      })
    );
  }

  /**
   * This is fired when grid filters are changed. We are re-fetching grid data on this event.
   * @param event GridFilter Event
   */
  async onGridFilterChanged(event: GridFilter) {
    const tabIndex = await firstValueFrom(
      this._sharedRecoveryCaseService.currentTabIndex$
    );

    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    if (!event) return;

    if (event.filters)
      Object.entries(event.filters).forEach(([key, value]) => {
        if (!event.filters) return;
        if (!value || (value as string[]).length === 0)
          delete event.filters[key];
      });

    this.dataManager.gridFilter = { ...event };

    if (this.dataManager.gridFilter.saveFilterConfiguration) {
      await this.dataManager.updateGridFiltersToServer(
        this.dataManager.gridFilter.defaultFilterApplied
      );
      this._toaster.showToast({
        message: 'Filter saved successfully!',
        type: 'success',
      } as Toast);
    }

    if (
      this.dataManager.gridFilter.newFiltersApplied &&
      (!this.dataManager.gridFilter.filters ||
        Object.keys(this.dataManager.gridFilter.filters).length === 0)
    ) {
      this.dataManager.resetFiltersAsPerCurrentTab(tabIndex, userId);
    } else {
      await this.dataManager.updateCustomerRecoveryRequestAsPerCustomFilters(
        tabIndex
      );
    }

    await this.fetchPageData();
  }

  /**
   * This method is triggered when grid column click event is triggered. We are writing various actions on this.
   * @param event Grid Column Cell Clicked Event
   * @returns void
   */
  onGridColumnClicked(cellData: CellClickEvent) {
    if (!cellData?.column) return;

    const { column, rowData } = cellData;

    // Handling when icon is clicked for specific columns
    if (['claimStatusId', 'comments'].includes(column)) {
      this.configureStatusAndCommentModal(cellData);
      return;
    }

    if (this.dataManager.eirAvailabilityIconClicked) {
      this.dataManager.eirAvailabilityIconClicked = false;
      return;
    }

    if (column === 'id') {
      this.handleRecoveryCaseClick(rowData!);
    }
  }

  private handleRecoveryCaseClick(rowData: { [key: string]: GridCellData }) {
    this._router.navigate(['customer-recovery/workflow/'], {
      queryParams: {
        caseNumber: rowData['recoveryCaseNumber'].value,
        containerNumber: rowData['containerNumber'].value,
      },
    });
  }

  /**
   * This event will be triggered whenever new rows are related in the grid.
   * @param selectedRows currently selected rows
   */
  onGridRowSelected(selectedRows: { [key: string]: unknown }[]) {
    this.selectedRowsOfGrid = selectedRows as [{ [key: string]: unknown }];
  }

  /**
   * This event is triggered from grid and we then store the new schema which has been modified.
   * @param newSchema new schema provided by the grid after column re-ordering.
   */
  async onGridSchemaChanged(newSchema: GridColumnSchema[]) {
    await this.dataManager.updateGridSchemaToServer(newSchema);
  }

  /**
   * This method validates the cases to be assigned and then assigns them to the current user.
   * @returns empty promise
   */
  async onAssignCasesToMeClicked(): Promise<void> {
    if (!this.validateCasesForAssignment()) return;
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const caseNumbers = this.selectedRowsOfGrid?.map(
      (row) => (row['recoveryCaseNumber'] as GridCellData).value as string
    );
    try {
      this.showLoader = true;
      const response = await this.dataManager.assignCasesToUserOnServer({
        assignedToUid: userId,
        recoveryCaseNumbers: caseNumbers,
        updatedBy: userId,
        updatedDttm: new Date(),
      } as UserClaimsAssignmentRequest);

      if (!response.caseAssignmentStatus.isUpdateSuccessful)
        throw response.caseAssignmentStatus.errorMessages;

      this.updateGridWithAssignedUsers(caseNumbers || [], userId);
      this._toaster.showToast({
        message: 'Cases assigned successfully!',
        type: 'success',
      } as Toast);
    } catch (error: any) {
      if (Array.isArray(error)) {
        const caseDetails = (error as string[])
          .map((x) => '\nCaseNumber: '.concat(x.split(',').join(' -  User: ')))
          .join(', ');
        this._toaster.showToast({
          message: `Error while assigning the cases ${caseDetails}`,
          type: 'error',
          durationInMilliSeconds: 10000,
        } as Toast);
        (error as string[]).forEach((error) =>
          this.updateGridWithAssignedUsers(
            [error.split(',')[0]],
            error.split(',')[1],
            true
          )
        );
      } else {
        const errorMessage =
          error?.error?.detailedErrors?.message ??
          'Error occurred while un-assigning cases';
        this._toaster.showToast({
          message: errorMessage,
          type: 'error',
        } as Toast);
      }
    } finally {
      this.showLoader = false;
    }
  }

  /**
   * This method validates the cases to be assigned and then assigns them to the current user.
   * @returns empty promise
   */
  async onUnassignCasesClicked(): Promise<void> {
    //Preparing list of case numbers for un-assignment
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const caseNumbers = this.selectedRowsOfGrid?.map(
      (row) => (row['recoveryCaseNumber'] as GridCellData).value as string
    );

    try {
      this.showLoader = true;

      //Calling case un-assigning API
      const response = await this.dataManager.unassignCasesOnServer({
        recoveryCaseNumbers: caseNumbers,
        updatedBy: userId,
      } as UserClaimsUnassignmentRequest);

      //Handling failure
      if (!response.isSuccess) throw response.detailedErrors.message;

      //Refreshing grid data
      this.fetchPageData();
      //Showing success message
      this._toaster.showToast({
        message: 'Cases unassigned successfully!',
        type: 'success',
      } as Toast);
    } catch (error: any) {
      //Showing error message
      const errorMessage =
        error?.error?.detailedErrors?.message ??
        'Error occurred while un-assigning cases';
      this._toaster.showToast({
        message: errorMessage,
        type: 'error',
      } as Toast);
    } finally {
      this.showLoader = false;
    }
  }

  /**
   * We create status-detail model in this method and use it to bind status info in the modal popup.
   * @param cellData Grid Column Cell Clicked Event
   * @returns void
   */
  configureStatusAndCommentModal(cellData: CellClickEvent) {
    const rowNumber = cellData.rowNumber ?? -1;

    if (
      !this.dataManager.gridData ||
      !cellData ||
      !cellData.rowData ||
      rowNumber == -1
    )
      return;

    const rowFromClickEvent: { [key: string]: GridCellData } =
      cellData.rowData as {
        [key: string]: GridCellData;
      };
    const dataRow = this.dataManager.gridData[rowNumber];

    const customerRecovery =
      dataRow.row['recoveryCaseNumber'].value ===
      rowFromClickEvent['recoveryCaseNumber'].value
        ? dataRow.row
        : dataRow.childRows?.find(
            (item: { [key: string]: GridCellData }) =>
              item['recoveryCaseNumber'].value ===
              rowFromClickEvent['recoveryCaseNumber'].value
          );

    if (!customerRecovery) return;

    this.statusDetails = {
      claimStatusId: customerRecovery['claimStatusId'].value as number,
      comment: customerRecovery['comments'].value as string,
      userName: 'SYSTEM',
      caseNumber: customerRecovery['recoveryCaseNumber'].value as string,
      cancelationReason: customerRecovery['cancelReason'].value as string,
      column: cellData.column || '',
      rowNumber: rowNumber,
    };

    this.IsStatusOrCommentModalVisible = true;
  }

  /**
   * This method calls an API to update the status as per the updates from modal popup and refreshes the grid.
   * @param statusDetails Input received from the status detail model.
   * @returns
   */
  async updateStatusAndCommentData(
    statusDetails: StatusDetailModel
  ): Promise<void> {
    this.IsStatusOrCommentModalVisible = false;
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    const response: ClaimStatusResponse =
      await this.dataManager.updateStatusAndCommentDataToServer(
        {
          userName: userId,
          cancelationReason: statusDetails.cancelationReason,
          comment: statusDetails.comment ?? '',
          claimStatusId: statusDetails.claimStatusId,
          updatedDttm: new Date(),
          workFlowStage: workflowStages.New.toString(),
        } as ClaimStatusRequest,
        statusDetails.caseNumber
      );

    if (!response.claimStatusChange.isClaimStatusChangeSuccessful) return;

    if (!this.dataManager.gridData) return;

    const row = this.dataManager.gridData[statusDetails.rowNumber].row;
    const customerRecovery =
      row['recoveryCaseNumber'].value === statusDetails.caseNumber
        ? row
        : this.dataManager.gridData[statusDetails.rowNumber].childRows?.find(
            (item: any) =>
              item['recoveryCaseNumber'].value === statusDetails.caseNumber
          );

    if (!customerRecovery) return;

    customerRecovery['comments'].value = statusDetails.comment;
    customerRecovery['cancelReason'].value = statusDetails.cancelationReason;
    customerRecovery['claimStatusId'].value =
      ClaimStatus[statusDetails.claimStatusId];
    if (
      statusTypeMaster
        .filter((status) => status.isCompleted)
        .map((x) => x.value)
        .includes(statusDetails.claimStatusId)
    ) {
      customerRecovery['claimStatusId'].disabled = true;
    }

    // With this we are keeping the linked rows expanded as update has been done on the child row.
    if (row['recoveryCaseNumber'].value !== statusDetails.caseNumber)
      this.dataManager.gridData[statusDetails.rowNumber].showChildRowData =
        true;

    row['recoveryCaseNumber'].disabled = true;
    this.updateGridWithNewData();

    this._toaster.showToast({
      message: 'Status updated successfully!',
      type: 'success',
    } as Toast);
  }

  /**
   * Updates grid data and properties as per selected grid tab
   * @param event - Details of selected tab
   * @returns
   */
  async onCaseTabChange(event: any) {
    this._sharedRecoveryCaseService.updateTabSelected(event.detail);
    await this.loadGridBasedOnCurrentTab(event.detail);
  }

  async loadGridBasedOnCurrentTab(tabIndex: number) {
    //Default status column properties for open case tabs
    const userId = (
      await firstValueFrom(this._dcrpAuthorizationService.loggedInUser$)
    ).uniqueId;
    this.showRowSelector = true;
    //Setting grid properties and status filters as per selected tab
    switch (tabIndex) {
      case 0: //All cases tab
        this.dataManager.loadInitialFiltersForAllCasesGridView();
        break;
      case 1: //My cases tab
        this.dataManager.resetFiltersAsPerCurrentTab(tabIndex, userId);
        break;
      case 2: //Completed cases tab
        this.showRowSelector = false;
        this.dataManager.resetFiltersAsPerCurrentTab(tabIndex, userId);
        break;
      default:
        return;
    }

    this.fetchPageData();
  }

  /**
   *
   * @returns If case selection is valid or not.
   */
  private validateCasesForAssignment(): boolean {
    if (!this.selectedRowsOfGrid) return false;

    const alreadyAssignedCases = this.selectedRowsOfGrid.filter(
      (row) =>
        !['SYSTEM', 'SYS'].includes(
          (row['assignedToName'] as GridCellData).value as string
        )
    );

    const alreadDeletedAssignedCases = this.selectedRowsOfGrid.filter((row) =>
      ['Deleted', 'Draft'].includes(
        (row['claimStatusId'] as GridCellData).value as string
      )
    );

    if (alreadDeletedAssignedCases.length > 0) {
      this._toaster.showToast({
        message: `Work orders for some cases are deleted, please select other cases.`,
        type: 'error',
      } as Toast);
      return false;
    }

    if (alreadyAssignedCases.length > 0) {
      this._toaster.showToast({
        message: `Some of the cases are already assigned to different users.`,
        type: 'warning',
      } as Toast);
      return false;
    }

    return true;
  }

  private updateGridWithAssignedUsers(
    caseNumbers: string[],
    username: string,
    markInvalid?: boolean
  ) {
    if (!this.dataManager.gridData || caseNumbers.length == 0 || !username)
      return;
    this.selectedRowsOfGrid = undefined;
    caseNumbers.forEach((caseNumber) => {
      const gridRowGroup = this.dataManager.gridData?.find(
        (row) =>
          row.row['recoveryCaseNumber'].value === caseNumber ||
          row.childRows?.find(
            (item: any) => item['recoveryCaseNumber'].value === caseNumber
          )
      );
      const row =
        gridRowGroup?.row['recoveryCaseNumber'].value === caseNumber
          ? gridRowGroup.row
          : gridRowGroup?.childRows?.find(
              (item: any) => item['recoveryCaseNumber'].value === caseNumber
            );
      if (gridRowGroup && row) {
        gridRowGroup.isRowSelectionInvalid = markInvalid;
        row['assignedToName'].value = username;
      }
    });
    this.updateGridWithNewData();
  }

  /**
   * This is important method where we are calling various APIs to get the consolidated data of the grid.
   * @param customerRecoveryRequest request object of the filter data
   */
  private async fetchPageData() {
    this.gridFilter = {
      ...this.dataManager.gridFilter,
    };
    this.showLoader = true;
    this.selectedRowsOfGrid = undefined;
    try {
      await this.dataManager.fetchDataFromServer();
    } catch (error) {
      this.updateGridWithNewData(true);
    } finally {
      this.showLoader = false;
    }
  }

  /**
   * Grid has `OnPush` as change detection strategy. So we need to update the reference of the input property of grid to reflect changes. Hence using spread operator here.
   * @returns
   */
  private updateGridWithNewData(makeEmpty?: boolean) {
    if (makeEmpty) {
      this.dataManager.gridData = null;
      return;
    }
    if (this.dataManager.gridData)
      this.dataManager.gridData = [...this.dataManager.gridData];
  }
}
