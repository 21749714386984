import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  GridCellData,
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { ContainerMovesComponent } from '../container-moves/container-moves.component';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import {
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  lastValueFrom,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  CONTAINER_MOVE_SELECT_CONFIRMATION_MSG,
  FIELD_NAME,
} from '../../../common/constants/app.constants';
import { ContainerProtectService } from '../../../common/services/container-protect/container-protect.service';
import { ContainerProtectDto } from '../../../common/models/containerProtectDto';
import { ContainerMoveDto } from '../../../common/models/containerMoveDto';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
import { workflowStages } from '../../../common/constants/temporary-constant';
import { SharedCustomerRecoveryCaseService } from '../../customer-recovery/shared-customer-recovery-case.service';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'booking-info',
  standalone: true,
  imports: [
    LibFormComponent,
    CommonModule,
    ContainerMovesComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './booking-info.component.html',
  styleUrl: './booking-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BookingInfoComponent implements OnInit {
  @Input() item!: TemplateModel;
  disable: boolean = false;
  items: TemplateModel[] = [];
  containerMovesSchema: GridColumnSchema[] = [];
  showContainerMoves: boolean = false;
  containerMovesItem!: TemplateModel;
  recoveryData!: CustomerRecoveryCaseDto | undefined;
  filterFromDate: string | undefined;
  filterToDate: string | undefined;
  containerMovesFromDate: string | undefined;
  containerMovesToDate: string | undefined;
  containerNumber: string | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  caseNumber: string | undefined;
  updateBookingNumberModal: boolean = false;
  selectedBookingNumber: string = '';
  bookingNumberErrorMsg: string = '';
  moves_confirmation_msg = CONTAINER_MOVE_SELECT_CONFIRMATION_MSG;

  customerRecoveryData$ = combineLatest([
    this._sharedRecoveryCaseService.recoveryCaseData$,
    this._customerRecoverySharedService.disableForm$,
  ]).pipe(
    tap(([recoveryData, disable]) => {
      this.recoveryData = recoveryData;
      this.disable = disable;
      this.containerNumber = recoveryData?.containerNumber;
      this.caseNumber = recoveryData?.recoveryCaseNumber;
      if (!this.selectedFromDate || !this.selectedToDate)
        this.setDateRangeForContainerMoves();
    })
  );

  shouldShowNotification$: Observable<boolean> = combineLatest([
    this._sharedRecoveryCaseService.bookingCargoDetails$,
    this.customerRecoveryData$,
    this._sharedRecoveryCaseService.currentStageId$,
  ]).pipe(
    tap(([bookingInfo]) => this.bindValueToItems(bookingInfo)),
    map(
      ([cargo, customerRecovery, currentStageId]) =>
        (['sys', 'system'].includes(
          (customerRecovery[0]?.createdBy ?? '').toLocaleLowerCase()
        ) ||
          currentStageId != workflowStages.New) &&
        (!cargo?.podSiteName || !cargo.polSiteName)
    )
  );

  invalidDateSelected: boolean = false;
  selectedMove: { [key: string]: GridCellData } | undefined;
  containerProtectionList: ContainerProtectDto[] = [];
  containerMovesDtoDetails: ContainerMoveDto | undefined;
  updateBookingNumberLoader: boolean = false;

  constructor(
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _containerProtectionService: ContainerProtectService,
    private _toaster: ToasterService,
    private _commonService: CommonService,
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  ngOnInit() {
    this.items = this.item.items as TemplateModel[];

    if (this.item && this.item.items) {
      this.containerMovesItem = this.item.items.filter(
        (o) => o.type === 'containerMoves'
      )[0];
    }
  }

  bookingNumberModalOpen() {
    this.updateBookingNumberModal = true;
  }

  async updateRecoveryInfo() {
    this.updateBookingNumberLoader = true;
    await this.getCpInfo(this.selectedMove!);

    // Handle missing container protection data
    if (
      !this.containerProtectionList ||
      this.containerProtectionList.length === 0
    ) {
      this._toaster.showToast({
        message: 'No CP data found for the selected move!',
        type: 'warning',
      } as Toast);
    }

    // Clone recovery data and extract selected move details
    const updatedRecoveryData: CustomerRecoveryCaseDto =
      this._sharedDataService.deepClone(this.recoveryData);
    if (!this.selectedMove) {
      this.updateBookingNumberLoader = false;
      return;
    }

    const {
      bookingNumber,
      operatorCode,
      placeOfDelivery: podLocation,
      bolNumber,
      activityLocation,
    } = this.extractMoveDetails();

    if (!bookingNumber || !podLocation || !activityLocation) {
      this.updateBookingNumberLoader = false;
      return;
    }
    const incidentDate = await firstValueFrom(
      this._customerRecoverySharedService.incidentDate$
    );

    // Get country details based on location
    const countries = await firstValueFrom(this._sharedDataService.countries$);
    const podCountry = this.getCountryFromLocation(countries, podLocation);
    const activityCountry = this.getCountryFromLocation(
      countries,
      activityLocation
    );

    // Extract CP details
    const filteredCPDetails = this.getFilteredCPDetails();
    const cpData = this.extractCPData(filteredCPDetails);
    const exchangeRateForCoverageCurrency =
      await this.getExChangRateForCurrency(cpData.coverageCurrency);

    const caseCurrency = cpData.localCurrency ?? 'USD';

    const exchangeRateForCaseCurrency =
      await this.getExChangRateForCurrency(caseCurrency);

    // Update coverage cost with exchange rate if needed
    const coverageCostInUSD = await this.convertToUSD(
      cpData.coverageCost,
      cpData.coverageCurrency,
      exchangeRateForCoverageCurrency
    );

    // Populate updated recovery data
    this.populateRecoveryData(updatedRecoveryData, {
      bookingNumber,
      operatorCode,
      bolNumber,
      cpData,
      podCountry,
      activityCountry,
      coverageCostInUSD,
      filteredCPDetails,
      exchangeRateForCaseCurrency,
      incidentDate,
      caseCurrency,
    });

    // Update the recovery case
    await this.updateCustomerRecoveryCase(updatedRecoveryData);
  }

  private async getExChangRateForCurrency(currency: string) {
    const date =
      !!this.recoveryData?.workOrderDate &&
      Date.parse(new Date(this.recoveryData?.workOrderDate).toISOString()) > 0
        ? new Date(this.recoveryData?.workOrderDate).toISOString()
        : !!this.recoveryData?.dateOfIncident &&
            Date.parse(
              new Date(this.recoveryData?.dateOfIncident).toISOString()
            ) > 0
          ? new Date(this.recoveryData?.dateOfIncident).toISOString()
          : new Date(this.recoveryData!.createdDate!).toISOString();

    const exchangeRateObj = await this.getExchangeRateForCurrency(
      date,
      currency
    );
    return exchangeRateObj?.exchangeRatePerUnit ?? 1;
  }

  // Extract move details from the selected move
  private extractMoveDetails() {
    if (!this.selectedMove)
      return {
        bookingNumber: '',
        operatorCode: '',
        placeOfDelivery: '',
        bolNumber: '',
        activityLocation: '',
      };
    return {
      bookingNumber: this.selectedMove['bookingNumber'].value as string,
      operatorCode: this.selectedMove['operatorCode'].value as string,
      placeOfDelivery: this.selectedMove['placeOfDelivery'].value as string,
      bolNumber: this.selectedMove['bolNumber'].value as string,
      activityLocation: this.selectedMove['activityLocation'].value as string,
    };
  }

  // Get country information based on location code
  private getCountryFromLocation(countries: any[], location: string) {
    return countries.find(
      (country) => country.code === location?.substring(0, 2)
    );
  }

  // Filter CP details based on container size
  private getFilteredCPDetails() {
    const details = this.containerProtectionList?.find(
      (i) => i.containerSize == this.containerMovesDtoDetails?.containerSize
    );
    return details || this.containerProtectionList[0];
  }

  // Extract CP-related data
  private extractCPData(filteredCPDetails: any) {
    const hasCPDetails = !!filteredCPDetails;
    return {
      coverageType: hasCPDetails ? filteredCPDetails.coverageType : '',
      coverageCurrency: hasCPDetails
        ? filteredCPDetails.coverageCurrency ?? 'USD'
        : 'USD',
      coverageCost: hasCPDetails ? filteredCPDetails.coverageAmount : 0,
      localCurrency: hasCPDetails ? filteredCPDetails.localCurrency : 'USD',
      containerSize: hasCPDetails ? filteredCPDetails.containerSize : null,
      isCpActiveCountry: hasCPDetails ? filteredCPDetails.isActive : null,
    };
  }

  // Convert coverage cost to USD if needed
  private async convertToUSD(
    coverageCost: number,
    coverageCurrency: string,
    exchangeRate: number
  ) {
    if (coverageCurrency.toUpperCase() !== 'USD') {
      return coverageCost * exchangeRate;
    }
    return coverageCost;
  }

  // Populate the updated recovery data
  private async populateRecoveryData(
    updatedRecoveryData: CustomerRecoveryCaseDto,
    details: any
  ) {
    const {
      bookingNumber,
      operatorCode,
      bolNumber,
      cpData,
      podCountry,
      activityCountry,
      coverageCostInUSD,
      filteredCPDetails,
      exchangeRateForCaseCurrency,
      incidentDate,
      caseCurrency,
    } = details;

    updatedRecoveryData.bookingNumber = bookingNumber;
    updatedRecoveryData.operatorCode = operatorCode;
    updatedRecoveryData.bolNumber = bolNumber;
    updatedRecoveryData.cpCoverageAmount = coverageCostInUSD;
    updatedRecoveryData.cpPurchased = cpData.coverageType;
    updatedRecoveryData.aboveCoverageCostUSD = Math.max(
      (this.recoveryData?.recoverableCostUSD ?? 0) - coverageCostInUSD,
      0
    );
    updatedRecoveryData.recoverableCostUSD =
      this.recoveryData?.recoverableCostUSD;
    updatedRecoveryData.caseCurrency = caseCurrency;

    updatedRecoveryData.withinCoverageCostUSD = Math.min(
      this.recoveryData?.recoverableCostUSD ?? 0,
      coverageCostInUSD
    );
    updatedRecoveryData.cpCoverageCurrency = 'USD';
    updatedRecoveryData.podCountryClusterCode =
      activityCountry?.clusterCode ?? podCountry?.clusterCode;
    updatedRecoveryData.podCountryClusterName =
      activityCountry?.clusterName ?? podCountry?.clusterName;
    updatedRecoveryData.podCountryCode =
      activityCountry?.code ?? podCountry?.code;
    updatedRecoveryData.podCountryId = activityCountry?.id ?? podCountry?.id;
    updatedRecoveryData.podCountryName =
      activityCountry?.name ?? podCountry?.name;
    updatedRecoveryData.podRegionCode =
      activityCountry?.regionCode ?? podCountry?.regionCode;
    updatedRecoveryData.podRegionName =
      activityCountry?.regionName ?? podCountry?.regionName;
    updatedRecoveryData.podLocalCurrency = cpData.localCurrency;
    updatedRecoveryData.containerSize =
      this.containerMovesDtoDetails?.containerSize;
    updatedRecoveryData.equipmentOwnershipType =
      this.containerMovesDtoDetails?.ownershipType;
    updatedRecoveryData.cpMasterContainerSize =
      filteredCPDetails?.containerSize;
    updatedRecoveryData.isCpActiveCountry = cpData.isCpActiveCountry;
    updatedRecoveryData.equipmentSubType =
      this.containerMovesDtoDetails?.containerType?.containerTypeCode;
    updatedRecoveryData.containerProdYear =
      this.containerMovesDtoDetails?.containerManufacturingYear;

    updatedRecoveryData.exchangeRateUSDCaseCurrency =
      exchangeRateForCaseCurrency;

    //don't change the condition as null is also considered a changed value
    updatedRecoveryData.dateOfIncident =
      incidentDate !== undefined
        ? incidentDate
        : updatedRecoveryData.dateOfIncident;
    //exceptional case for cp3 and cp4
    if (['CP3', 'CP4'].includes(cpData.coverageType)) {
      updatedRecoveryData.withinCoverageCostUSD =
        updatedRecoveryData.recoverableCostUSD;
      updatedRecoveryData.withinCoverageCostCaseCurrency =
        updatedRecoveryData.recoverableCostCaseCurrency;
      updatedRecoveryData.aboveCoverageCostUSD = 0;
      updatedRecoveryData.aboveCoverageCostCaseCurrency = 0;
    }
    //updatedRecoveryData.importReturn = podCountry?.code === shopCountry?.code;
  }

  // Update the customer recovery case and show the success toast
  private async updateCustomerRecoveryCase(
    updatedRecoveryData: CustomerRecoveryCaseDto
  ) {
    const updateCustomerRecovery$ = this._customerRecoveryService
      .customerRecoveryClaimsUpdatePost({
        customerRecoveryCaseDto: updatedRecoveryData,
      })
      .pipe(
        tap(() => {
          this._sharedRecoveryCaseService.reloadRecoveryCaseData();
          this.updateBookingNumberModal = false;
          this.showContainerMoves = false;
          this.updateBookingNumberLoader = false;
          this._toaster.showToast({
            message: 'Booking details updated!',
            type: 'success',
          } as Toast);
        }),
        catchError(() => {
          this.updateBookingNumberLoader = false;
          this._toaster.showToast({
            message: 'Failed to update booking details!',
            type: 'error',
          } as Toast);
          return of(null);
        })
      );

    await firstValueFrom(updateCustomerRecovery$);
  }

  async getExchangeRateForCurrency(date: string, exchangeCurrency: string) {
    const exchangeRates = await lastValueFrom(
      this._commonService
        .commonCurrencyAndExchangeRatesForQuotationDateGet(date)
        .pipe(
          map((res) => {
            if (!res || !res.data) return [];
            return res.data;
          }),
          shareReplay(1)
        )
    );
    const exchangeRateObj = exchangeRates?.filter(
      (currency) => currency.quotationCurrency == exchangeCurrency
    )[0];

    return exchangeRateObj;
  }

  bindValueToItems(bookingValues: gcssBookingInfo | undefined) {
    if (!bookingValues) return;
    this.items.forEach((item: TemplateModel) => {
      item.disabled = this.disable;
      const bookingCargoKeyValue = bookingValues as {
        [key: string]: unknown;
      };

      if (item.name == FIELD_NAME.SERVICE_RECEIVE_DELIVERY) {
        item.value =
          bookingValues.exportServiceMode +
          '/' +
          bookingValues.importServiceMode;
      } else if (item.name == FIELD_NAME.EQUIPMENT_NUMBER) {
        item.value = this.containerNumber;
      } else if (item.name == FIELD_NAME.COVERAGE_AMOUNT) {
        item.value =
          this.recoveryData?.cpCoverageCurrency?.toUpperCase() == 'USD'
            ? '$' + (this.recoveryData.cpCoverageAmount ?? 0)
            : this.recoveryData?.cpCoverageCurrency +
              ' ' +
              (this.recoveryData?.cpCoverageAmount ?? 0);
      } else if (item.name == FIELD_NAME.CP_PLAN) {
        item.value = this.recoveryData?.cpPurchased ?? '-';
      } else if (item.name == FIELD_NAME.ABOVE_RECOVERABLE_COST) {
        item.value =
          '$' +
          (this.formatDecimal(this.recoveryData?.aboveCoverageCostUSD) ?? 0);
      } else if (item.name == FIELD_NAME.RECOVERABLE_COST_USD) {
        item.value =
          '$' +
          (this.formatDecimal(this.recoveryData?.recoverableCostUSD) ?? 0);
      } else {
        item.value = bookingCargoKeyValue[item.name as string];
      }

      if (!item.value) item.value = '-';
    });
  }

  setDateRangeForContainerMoves() {
    let dateToFetchMoves = new Date();
    let toDatePlusVal = 10;

    if (
      this.recoveryData?.workOrderDate &&
      this.recoveryData?.workOrderDate.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchMoves = new Date(this.recoveryData.workOrderDate);
    } else if (
      this.recoveryData?.dateOfIncident &&
      this.recoveryData.dateOfIncident.toString() != '0001-01-01T00:00:00'
    ) {
      dateToFetchMoves =
        new Date(this.recoveryData.dateOfIncident) ?? new Date();
    } else {
      toDatePlusVal = 0;
    }

    const toDate = new Date(dateToFetchMoves.getTime());
    const fromDate = new Date(dateToFetchMoves.getTime());

    toDate.setDate(toDate.getDate() + toDatePlusVal);
    fromDate.setDate(fromDate.getDate() - 30);

    const formatDateOptions = {
      timeZone: 'UTC',
    };

    this.selectedFromDate = fromDate.toLocaleDateString(
      'en-GB',
      formatDateOptions
    );
    this.selectedToDate = toDate.toLocaleDateString('en-GB', formatDateOptions);

    this.containerMovesFromDate = this.selectedFromDate;
    this.containerMovesToDate = this.selectedToDate;
  }

  OnDateSelectHandler(type: string, event: any) {
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
    } else {
      this.selectedToDate = event.target.value;
    }
  }

  updateMoves() {
    const isValidDates =
      this.isValidDateFormat(this.selectedFromDate!) &&
      this.isValidDateFormat(this.selectedToDate!);

    if (isValidDates) {
      this.containerMovesFromDate = this.selectedFromDate;
      this.containerMovesToDate = this.selectedToDate;
    }

    this.invalidDateSelected = !isValidDates;
  }

  showHideContainerMovesLink(): void {
    this.showContainerMoves = !this.showContainerMoves;
  }

  private isValidDateFormat(dateString: string): boolean {
    // Regular expression for dd/mm/yyyy format
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Check if the input string matches the regex
    const isValid = regex.test(dateString);

    return isValid;
  }

  private formatDecimal(value: number | undefined): number {
    if (!value) return 0;
    return Math.round(value * 100) / 100;
  }

  OnMovesRowSelectionChanged(
    event: [
      {
        [key: string]: GridCellData;
      },
    ]
  ) {
    this.selectedMove = event[0];
    this.selectedBookingNumber = this.selectedMove['bookingNumber']
      .value as string;
    this.bookingNumberModalOpen();
  }

  /**
   * Sets selected container move from grid
   * @param selectedMove - Selected container move
   */
  async getCpInfo(selectedMove: { [key: string]: GridCellData }) {
    if (!this.selectedMove) return;
    //Extracting data from selected move
    const bookingNumber = selectedMove['bookingNumber'].value as string;
    const operatorCode = selectedMove['operatorCode'].value as string;
    const podLocation = selectedMove['placeOfDelivery'].value as string;
    const bolNumber = selectedMove['bolNumber'].value as string;

    const res = await firstValueFrom(
      this._containerProtectionService
        .containerProtectContainersContainerNumberGet(
          this.recoveryData?.containerNumber ?? '',
          operatorCode,
          podLocation?.substring(0, 2),
          bookingNumber,
          bolNumber
        )
        .pipe(
          map((res) => {
            if (!res || !res.data) return [];
            return res.data;
          }),
          catchError((_) => {
            return of([]);
          })
        )
    );
    this.containerProtectionList = res;
  }

  onContainerMovesDataFetched(containerMovesDto: ContainerMoveDto) {
    this.containerMovesDtoDetails = containerMovesDto;
  }
}
