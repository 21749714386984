import { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const apiVersionInterceptor: HttpInterceptorFn = (req, next) => {
  if (!req.headers.get('API-Version')) {
    req = req.clone({
      setHeaders: {
        'API-Version': '1.0',
      },
    });
  }
  return next(req).pipe(catchError((error) => throwError(() => error)));
};
