import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HelpAndFaqService } from '../../../common/services/help-and-faq/help-and-faq.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-help-faq-drawer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-faq-drawer.component.html',
  styleUrl: './help-faq-drawer.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HelpFaqDrawerComponent {
  openDrawer$: Observable<boolean> = this._helpAnfFaq.listener$;
  constructor(private _helpAnfFaq: HelpAndFaqService) {}

  close(): void {
    this._helpAnfFaq.closeDrawer();
  }

  openRedirectUrl(val: string): void {
    switch (val) {
      case 'View All': {
        window.open(location.origin + '/help-faq/faq');
        break;
      }
      case 'User Guide': {
        window.open(location.origin + '/help-faq/user-guide');
        break;
      }
      case 'Report an issue': {
        window.open(
          'https://maersk.service-now.com/end_user_portal?id=report_issue&sys_id=3ee96e21fbe95650b643f197beefdcd0'
        );
        break;
      }
    }
  }
}
